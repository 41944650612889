// src/Marquee.js
import React, {useEffect,useState} from "react";
// import "./Marquee.css";
const messages = [
  "Join Mikptech Innovations and master Web Development today!",
  "Transform your career with AI courses at Mikptech Innovations.",
  "Become a Project Management expert with Mikptech Innovations.",
  "Learn Product Design from industry leaders at Mikptech Innovations.",
  "Unlock the power of Data Analysis at Mikptech Innovations.",
  "Master UI/UX Design with Mikptech Innovations.",
  "Secure your future with Cybersecurity courses at Mikptech Innovations.",
  "Enhance your skills with DevOps training at Mikptech Innovations.",
  "Join Mikptech Innovations for hands-on Web Development training.",
  "Advance your career with AI and Machine Learning at Mikptech.",
  "Get certified in Project Management with Mikptech Innovations.",
  "Create stunning designs with our Product Design course.",
  "Turn data into insights with our Data Analysis programs.",
  "Design intuitive interfaces with our UI/UX courses.",
  "Protect digital assets with Cybersecurity training at Mikptech.",
  "Streamline operations with our DevOps courses.",
  "Mikptech Innovations: Your gateway to a tech career.",
  "Learn the latest in Web Development at Mikptech Innovations.",
  "Stay ahead in AI with Mikptech Innovation's cutting-edge courses.",
  "Manage projects like a pro with Mikptech Innovation's training.",
  "Design products that users love with Mikptech Innovations.",
  "Harness the power of data with Mikptech Innovations.",
  "Create user-friendly designs with UI/UX courses at Mikptech.",
  "Defend against cyber threats with Mikptech Innovations.",
  "Optimize your workflows with Mikptech Innovation's DevOps courses.",
  "Mikptech Innovations: Shaping tech experts in Africa, Nigeria.",
  "Jumpstart your tech career with Mikptech Innovations.",
  "Learn from industry experts at Mikptech Innovations.",
  "Join Mikptech Innovations for comprehensive tech training.",
  "Achieve your career goals with Mikptech Innovations.",
  "Mikptech Innovations: Where innovation meets education.",
  "Master front-end and back-end development with Mikptech.",
  "Explore the future of AI with Mikptech Innovations.",
  "Lead successful projects with training from Mikptech.",
  "Design innovative products with Mikptech Innovations.",
  "Analyze data like a pro with Mikptech Innovations.",
  "Create seamless user experiences with UI/UX courses.",
  "Stay safe online with Cybersecurity training at Mikptech.",
  "Mikptech Innovations: Your path to a tech-savvy future.",
  "Upgrade your skills with DevOps courses at Mikptech.",
  "Learn to build dynamic websites at Mikptech Innovations.",
  "Advance in AI and ML with Mikptech Innovations.",
  "Excel in Project Management with Mikptech Innovations.",
  "Create impactful product designs with Mikptech Innovations.",
  "Become a data expert with Mikptech Innovations.",
  "Craft beautiful user interfaces with UI/UX courses.",
  "Guard against cyber threats with Mikptech Innovations.",
  "Enhance your DevOps skills with Mikptech Innovations.",
  "Mikptech Innovations: Empowering the next generation of tech leaders.",
  "Build your tech career with Mikptech Innovations.",
  "Join Mikptech Innovations and become a Web Development pro.",
  "Dive into AI with Mikptech Innovation's specialized courses.",
  "Manage projects effectively with Mikptech Innovation's training.",
  "Design products that stand out with Mikptech Innovations.",
  "Turn data into actionable insights with Mikptech Innovations.",
  "Create intuitive designs with UI/UX training at Mikptech.",
  "Stay protected online with Cybersecurity courses at Mikptech.",
  "Optimize development processes with Mikptech Innovation's DevOps training.",
  "Mikptech Innovations: Leading tech education in Africa, Nigeria.",
  "Achieve tech excellence with Mikptech Innovations.",
  "Learn Web Development from the best at Mikptech Innovations.",
  "Stay on the cutting edge of AI with Mikptech Innovations.",
  "Become a Project Management expert with Mikptech.",
  "Design innovative products with courses at Mikptech Innovations.",
  "Master data analysis techniques with Mikptech Innovations.",
  "Create engaging user experiences with UI/UX courses at Mikptech.",
  "Defend digital infrastructures with Cybersecurity training.",
  "Streamline your operations with DevOps courses at Mikptech.",
  "Mikptech Innovations: Pioneering tech training in Africa.",
  "Enhance your tech skills with Mikptech Innovations.",
  "Join Mikptech Innovations for world-class Web Development training.",
  "Explore the possibilities of AI with Mikptech Innovations.",
  "Lead projects to success with Mikptech Innovation's training.",
  "Design user-centered products with Mikptech Innovations.",
  "Unlock data insights with Mikptech Innovations.",
  "Create visually appealing interfaces with UI/UX courses.",
  "Protect against cyber attacks with Mikptech Innovations.",
  "Optimize software development with DevOps courses at Mikptech.",
  "Mikptech Innovations: Your partner in tech education.",
  "Build a successful tech career with Mikptech Innovations.",
  "Join Mikptech Innovations for expert-led Web Development courses.",
  "Advance your AI knowledge with Mikptech Innovations.",
  "Become a certified Project Manager with Mikptech Innovations.",
  "Learn to design top-notch products with Mikptech Innovations.",
  "Analyze and interpret data effectively with Mikptech Innovations.",
  "Craft user-friendly designs with UI/UX courses at Mikptech.",
  "Stay ahead of cyber threats with Cybersecurity training.",
  "Enhance your DevOps capabilities with Mikptech Innovations.",
  "Mikptech Innovations: Shaping the future of tech in Africa.",
  "Excel in tech with Mikptech Innovation's comprehensive training.",
  "Master Web Development at Mikptech Innovations.",
  "Stay current with AI advancements at Mikptech Innovations.",
  "Manage projects efficiently with Mikptech Innovation's training.",
  "Design impactful products with Mikptech Innovations.",
  "Turn data into gold with Mikptech Innovations.",
  "Create stunning user experiences with UI/UX courses at Mikptech.",
  "Guard your digital world with Cybersecurity courses at Mikptech.",
  "Optimize your development pipeline with DevOps training at Mikptech.",
  "Mikptech Innovations: Innovating tech education in Africa.",
  "Build your dream tech career with Mikptech Innovations.",
  "Join Mikptech Innovations for unparalleled Web Development training.",
  "Dive deep into AI with Mikptech Innovation's specialized courses.",
  "Lead projects to success with Mikptech's Project Management training.",
  "Design user-centric products with Mikptech Innovations.",
  "Master the art of data analysis with Mikptech Innovations.",
  "Create intuitive user interfaces with UI/UX training at Mikptech.",
  "Protect your digital assets with Cybersecurity training at Mikptech.",
  "Streamline your development processes with DevOps courses.",
  "Mikptech Innovations: Empowering tech enthusiasts in Africa.",
  "Enhance your tech career with Mikptech Innovations.",
  "Join Mikptech Innovations and excel in Web Development.",
  "Stay ahead in AI with Mikptech Innovation's cutting-edge courses.",
  "Manage projects like a pro with Mikptech's Project Management training.",
  "Design innovative products with Mikptech Innovations.",
  "Analyze data like a professional with Mikptech Innovations.",
  "Create seamless user experiences with UI/UX courses at Mikptech.",
  "Stay safe online with Cybersecurity training at Mikptech.",
  "Optimize your workflows with DevOps training at Mikptech.",
  "Mikptech Innovations: Leading the way in tech education.",
  "Achieve your tech goals with Mikptech Innovations.",
  "Master Web Development with Mikptech Innovation's expert training.",
  "Explore the future of AI with Mikptech Innovations.",
  "Lead successful projects with Mikptech Innovation's training.",
  "Design products that users love with Mikptech Innovations.",
  "Harness the power of data with Mikptech Innovations.",
  "Create user-friendly designs with UI/UX courses at Mikptech.",
  "Defend against cyber threats with Mikptech Innovation's Cybersecurity training.",
  "Enhance your DevOps skills with Mikptech Innovation's courses.",
  "Mikptech Innovations: Your path to tech success.",
  "Build your tech future with Mikptech Innovations.",
  "Join Mikptech Innovations for hands-on Web Development training.",
  "Advance in AI and Machine Learning with Mikptech Innovations.",
  "Get certified in Project Management with Mikptech Innovations.",
  "Create stunning product designs with Mikptech Innovations.",
  "Turn data into insights with Mikptech Innovation's Data Analysis courses.",
  "Design intuitive interfaces with Mikptech Innovation's UI/UX courses.",
  "Protect digital assets with Mikptech Innovation's Cybersecurity training.",
  "Streamline operations with Mikptech Innovation's DevOps training.",
  "Mikptech Innovations: Shaping the tech leaders of tomorrow.",
  "Jumpstart your tech career with Mikptech Innovations.",
  "Learn from industry experts at Mikptech Innovations.",
  "Join Mikptech Innovations for comprehensive tech training.",
  "Achieve your career goals with Mikptech Innovations.",
  "Mikptech Innovations: Where innovation meets education.",
  "Master front-end and back-end development with Mikptech.",
  "Explore the future of AI with Mikptech Innovations.",
  "Lead successful projects with training from Mikptech.",
  "Design innovative products with Mikptech Innovations.",
  "Analyze data like a pro with Mikptech Innovations.",
  "Create seamless user experiences with UI/UX courses.",
  "Stay safe online with Cybersecurity training at Mikptech.",
  "Mikptech Innovations: Your path to a tech-savvy future.",
  "Upgrade your skills with DevOps courses at Mikptech.",
  "Learn to build dynamic websites at Mikptech Innovations.",
  "Advance in AI and ML with Mikptech Innovations.",
  "Excel in Project Management with Mikptech Innovations.",
  "Create impactful product designs with Mikptech Innovations.",
  "Become a data expert with Mikptech Innovations.",
  "Craft beautiful user interfaces with UI/UX courses.",
  "Guard against cyber threats with Mikptech Innovations",
  "Enhance your DevOps skills with Mikptech Innovations.",
  "Mikptech Innovations: Empowering the next generation of tech leaders.",
  "Build your tech career with Mikptech Innovations.",
  "Join Mikptech Innovations and become a Web Development pro.",
  "Dive into AI with Mikptech Innovation's specialized courses.",
  "Manage projects effectively with Mikptech Innovation's training.",
  "Design products that stand out with Mikptech Innovations.",
  "Turn data into actionable insights with Mikptech Innovations.",
  "Create intuitive designs with UI/UX training at Mikptech.",
  "Stay protected online with Cybersecurity courses at Mikptech.",
  "Optimize development processes with Mikptech Innovation's DevOps training.",
  "Mikptech Innovations: Leading tech education in Africa, Nigeria.",
  "Achieve tech excellence with Mikptech Innovations.",
  "Learn Web Development from the best at Mikptech Innovations.",
  "Stay on the cutting edge of AI with Mikptech Innovations.",
  "Become a Project Management expert with Mikptech.",
  "Design innovative products with courses at Mikptech Innovations.",
  "Master data analysis techniques with Mikptech Innovations.",
  "Create engaging user experiences with UI/UX courses at Mikptech.",
  "Defend digital infrastructures with Cybersecurity training.",
  "Streamline your operations with DevOps courses at Mikptech.",
  "Mikptech Innovations: Pioneering tech training in Africa.",
  "Enhance your tech skills with Mikptech Innovations.",
  "Join Mikptech Innovations for world-class Web Development training.",
  "Explore the possibilities of AI with Mikptech Innovations.",
  "Lead projects to success with Mikptech Innovation's training.",
  "Design user-centered products with Mikptech Innovations.",
  "Unlock data insights with Mikptech Innovations.",
  "Create visually appealing interfaces with UI/UX courses.",
  "Protect against cyber attacks with Mikptech Innovations.",
  "Optimize software development with DevOps courses at Mikptech.",
  "Mikptech Innovations: Innovating tech education in Africa.",
  "Build your dream tech career with Mikptech Innovations.",
  "Join Mikptech Innovations for unparalleled Web Development training.",
  "Dive deep into AI with Mikptech Innovation's specialized courses.",
  "Lead projects to success with Mikptech's Project Management training.",
  "Design user-centric products with Mikptech Innovations.",
  "Master the art of data analysis with Mikptech Innovations.",
  "Create intuitive user interfaces with UI/UX training at Mikptech.",
  "Protect your digital assets with Cybersecurity training at Mikptech.",
  "Streamline your development processes with DevOps courses.",
  "Mikptech Innovations: Empowering tech enthusiasts in Africa.",
  "Enhance your tech career with Mikptech Innovations.",
  "Join Mikptech Innovations and excel in Web Development.",
  "Stay ahead in AI with Mikptech Innovation's cutting-edge courses.",
  "Manage projects like a pro with Mikptech's Project Management training.",
  "Design innovative products with Mikptech Innovations.",
  "Analyze data like a professional with Mikptech Innovations.",
  "Create seamless user experiences with UI/UX courses at Mikptech.",
  "Stay safe online with Cybersecurity training at Mikptech.",
  "Optimize your workflows with DevOps training at Mikptech.",
  "Mikptech Innovations: Leading the way in tech education.",
  "Achieve your tech goals with Mikptech Innovations.",
  "Master Web Development with Mikptech Innovation's expert training.",
  "Explore the future of AI with Mikptech Innovations.",
  "Lead successful projects with Mikptech Innovation's training.",
  "Design products that users love with Mikptech Innovations.",
  "Harness the power of data with Mikptech Innovations.",
  "Create user-friendly designs with UI/UX courses at Mikptech.",
  "Defend against cyber threats with Mikptech Innovation's Cybersecurity training.",
  "Enhance your DevOps skills with Mikptech Innovation's courses.",
  "Mikptech Innovations: Your path to tech success.",
  "Build your tech future with Mikptech Innovations.",
  "Join Mikptech Innovations for hands-on Web Development training.",
  "Advance in AI and Machine Learning with Mikptech Innovations.",
  "Get certified in Project Management with Mikptech Innovations.",
  "Create stunning product designs with Mikptech Innovations.",
  "Turn data into insights with Mikptech Innovation's Data Analysis courses.",
  "Design intuitive interfaces with Mikptech Innovation's UI/UX courses.",
  "Protect digital assets with Mikptech Innovation's Cybersecurity training.",
  "Streamline operations with Mikptech Innovation's DevOps training.",
  "Mikptech Innovations: Shaping the tech leaders of tomorrow.",
  "Jumpstart your tech career with Mikptech Innovations.",
  "Learn from industry experts at Mikptech Innovations.",
  "Join Mikptech Innovations for comprehensive tech training.",
  "Achieve your career goals with Mikptech Innovations.",
  "Mikptech Innovations: Where innovation meets education.",
  "Master front-end and back-end development with Mikptech.",
  "Explore the future of AI with Mikptech Innovations.",
  "Lead successful projects with training from Mikptech.",
  "Design innovative products with Mikptech Innovations.",
  "Analyze data like a pro with Mikptech Innovations.",
  "Create seamless user experiences with UI/UX courses.",
  "Stay safe online with Cybersecurity training at Mikptech.",
  "Mikptech Innovations: Your path to a tech-savvy future.",
  "Upgrade your skills with DevOps courses at Mikptech.",
  "Learn to build dynamic websites at Mikptech Innovations.",
  "Advance in AI and ML with Mikptech Innovations.",
  "Excel in Project Management with Mikptech Innovations.",
  "Create impactful product designs with Mikptech Innovations.",
  "Become a data expert with Mikptech Innovations.",
  "Craft beautiful user interfaces with UI/UX courses.",
  "Guard against cyber threats with Mikptech Innovations.",
  "Enhance your DevOps skills with Mikptech Innovations.",
  "Mikptech Innovations: Empowering the next generation of tech leaders.",
  "Build your tech career with Mikptech Innovations.",
  "Join Mikptech Innovations and become a Web Development pro.",
  "Dive into AI with Mikptech Innovation's specialized courses.",
  "Manage projects effectively with Mikptech Innovation's training.",
  "Design products that stand out with Mikptech Innovations.",
  "Turn data into actionable insights with Mikptech Innovations.",
  "Create intuitive designs with UI/UX training at Mikptech.",
  "Stay protected online with Cybersecurity courses at Mikptech.",
  "Optimize development processes with Mikptech Innovation's DevOps training.",
  "Mikptech Innovations: Leading tech education in Africa, Nigeria.",
  "Achieve tech excellence with Mikptech Innovations.",
  "Learn Web Development from the best at Mikptech Innovations.",
  "Stay on the cutting edge of AI with Mikptech Innovations.",
  "Become a Project Management expert with Mikptech.",
  "Design innovative products with courses at Mikptech Innovations.",
  "Master data analysis techniques with Mikptech Innovations.",
  "Create engaging user experiences with UI/UX courses at Mikptech.",
  "Defend digital infrastructures with Cybersecurity training.",
  "Streamline your operations with DevOps courses at Mikptech.",
  "Mikptech Innovations: Pioneering tech training in Africa.",
  "Enhance your tech skills with Mikptech Innovations.",
  "Join Mikptech Innovations for world-class Web Development training.",
  "Explore the possibilities of AI with Mikptech Innovations.",
  "Lead projects to success with Mikptech Innovation's training.",
  "Design user-centered products with Mikptech Innovations.",
  "Unlock data insights with Mikptech Innovations.",
  "Create visually appealing interfaces with UI/UX courses.",
  "Protect against cyber attacks with Mikptech Innovations.",
  "Optimize software development with DevOps courses at Mikptech.",
  "Mikptech Innovations: Innovating tech education in Africa.",
  "Build your dream tech career with Mikptech Innovations.",
  "Join Mikptech Innovations for unparalleled Web Development training.",
  "Dive deep into AI with Mikptech Innovation's specialized courses.",
  "Lead projects to success with Mikptech's Project Management training.",
  "Design user-centric products with Mikptech Innovations.",
  "Master the art of data analysis with Mikptech Innovations.",
  "Create intuitive user interfaces with UI/UX training at Mikptech.",
  "Protect your digital assets with Cybersecurity training at Mikptech.",
  "Streamline your development processes with DevOps courses.",
  "Mikptech Innovations: Empowering tech enthusiasts in Africa.",
  "Enhance your tech career with Mikptech Innovations.",
  "Join Mikptech Innovations and excel in Web Development.",
  "Stay ahead in AI with Mikptech Innovation's cutting-edge courses.",
  "Manage projects like a pro with Mikptech's Project Management training.",
  "Design innovative products with Mikptech Innovations.",
  "Analyze data like a professional with Mikptech Innovations.",
  "Create seamless user experiences with UI/UX courses at Mikptech.",
  "Stay safe online with Cybersecurity training at Mikptech.",
  "Optimize your workflows with DevOps training at Mikptech.",
  "Mikptech Innovations: Leading the way in tech education.",
  "Achieve your tech goals with Mikptech Innovations.",
  "Master Web Development with Mikptech Innovation's expert training.",
  "Explore the future of AI with Mikptech Innovations.",
  "Lead successful projects with Mikptech Innovation's training.",
  "Design products that users love with Mikptech Innovations.",
  "Harness the power of data with Mikptech Innovations.",
  "Create user-friendly designs with UI/UX courses at Mikptech.",
  "Defend against cyber threats with Mikptech Innovation's Cybersecurity training.",
  "Enhance your DevOps skills with Mikptech Innovation's courses.",
  "Mikptech Innovations: Your path to tech success.",
  "Build your tech future with Mikptech Innovations.",
  "Join Mikptech Innovations for hands-on Web Development training.",
  "Advance in AI and Machine Learning with Mikptech Innovations.",
  "Get certified in Project Management with Mikptech Innovations.",
  "Create stunning product designs with Mikptech Innovations.",
  "Turn data into insights with Mikptech Innovation's Data Analysis courses.",
  "Design intuitive interfaces with Mikptech Innovation's UI/UX courses.",
  "Protect digital assets with Mikptech Innovation's Cybersecurity training.",
  "Streamline operations with Mikptech Innovation's DevOps training.",
  "Mikptech Innovations: Shaping the tech leaders of tomorrow.",
  "Jumpstart your tech career with Mikptech Innovations.",
  "Learn from industry experts at Mikptech Innovations.",
  "Join Mikptech Innovations for comprehensive tech training.",
  "Achieve your career goals with Mikptech Innovations.",
  "Mikptech Innovations: Where innovation meets education.",
  "Master front-end and back-end development with Mikptech.",
  "Explore the future of AI with Mikptech Innovations.",
  "Lead successful projects with training from Mikptech.",
  "Design innovative products with Mikptech Innovations.",
  "Analyze data like a pro with Mikptech Innovations.",
  "Create seamless user experiences with UI/UX courses.",
  "Stay safe online with Cybersecurity training at Mikptech.",
  "Mikptech Innovations: Your path to a tech-savvy future.",
  "Upgrade your skills with DevOps courses at Mikptech.",
  "Learn to build dynamic websites at Mikptech Innovations.",
  "Advance in AI and ML with Mikptech Innovations.",
  "Excel in Project Management with Mikptech Innovations.",
  "Create impactful product designs with Mikptech Innovations.",
  "Become a data expert with Mikptech Innovations.",
  "Craft beautiful user interfaces with UI/UX courses.",
  "Guard against cyber threats with Mikptech Innovations.",
  "Enhance your DevOps skills with Mikptech Innovations.",
  "Mikptech Innovations: Empowering the next generation of tech leaders.",
  "Build your tech career with Mikptech Innovations.",
  "Join Mikptech Innovations and become a Web Development pro.",
  "Dive into AI with Mikptech Innovation's specialized courses.",
  "Manage projects effectively with Mikptech Innovation's training.",
  "Design products that stand out with Mikptech Innovations.",
  "Turn data into actionable insights with Mikptech Innovations.",
  "Create intuitive designs with UI/UX training at Mikptech.",
  "Stay protected online with Cybersecurity courses at Mikptech.",
  "Optimize development processes with Mikptech Innovation's DevOps training.",
  "Mikptech Innovations: Leading tech education in Africa, Nigeria.",
  "Achieve tech excellence with Mikptech Innovations.",
  "Learn Web Development from the best at Mikptech Innovations.",
  "Stay on the cutting edge of AI with Mikptech Innovations.",
  "Become a Project Management expert with Mikptech.",
  "Design innovative products with courses at Mikptech Innovations.",
  "Master data analysis techniques with Mikptech Innovations.",
  "Create engaging user experiences with UI/UX courses at Mikptech.",
  "Defend digital infrastructures with Cybersecurity training.",
  "Streamline your operations with DevOps courses at Mikptech.",
  "Mikptech Innovations: Pioneering tech training in Africa.",
  "Enhance your tech skills with Mikptech Innovations.",
  "Join Mikptech Innovations for world-class Web Development training.",
  "Explore the possibilities of AI with Mikptech Innovations.",
  "Lead projects to success with Mikptech Innovation's training.",
  "Design user-centered products with Mikptech Innovations.",
  "Unlock data insights with Mikptech Innovations.",
  "Create visually appealing interfaces with UI/UX courses.",
  "Protect against cyber attacks with Mikptech Innovations."
];



const Marquee = () => {
    const [currentMessage, setCurrentMessage] = useState("");

    useEffect(() => {
      // Function to pick a random message
      const pickRandomMessage = () => {
        const randomIndex = Math.floor(Math.random() * messages.length);
        setCurrentMessage(messages[randomIndex]);
      };

      // Pick an initial message
      pickRandomMessage();

      // Set an interval to pick a new message every 30 seconds
      const intervalId = setInterval(pickRandomMessage, 40000);

      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }, [messages]);
  return (
    <footer className="footerm">
      <div className="marquee">
        <div className="marquee-inner">
          <p className="text-ldanger">{currentMessage}</p>
        </div>
      </div>
    </footer>
  );
};

export default Marquee;
