import React from 'react'

const Stats = () => {
  return (
    <div>
      <div className="important_bg">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="important_box">
                <h3>200+</h3>
                <span>Teachers</span>
              </div>
            </div>
            <div className="col col-xs-12">
              <div className="important_box">
                <h3>20+</h3>
                <span>Career paths</span>
              </div>
            </div>
            <div className="col col-xs-12">
              <div className="important_box">
                <h3>50+</h3>
                <span>Courses</span>
              </div>
            </div>
            <div className="col col-xs-12">
              <div className="important_box">
                <h3>200+</h3>
                <span>Members</span>
              </div>
            </div>
            <div className="col col-xs-12">
              <div className="important_box">
                <h3>5+</h3>
                <span>countries</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats
