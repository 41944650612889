import React, { useState, useRef, useEffect } from "react";
// import {Link} from "react-router-dom"
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput,
} from "mdb-react-ui-kit";
import { Dialog } from "primereact/dialog";

import "./sign.css";
import { Link } from "react-router-dom";
import { useNavigate ,useParams } from "react-router-dom";
// import "./sign.css";
import Cookies from "universal-cookie";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { Input } from "semantic-ui-react";

import Spinner from "react-bootstrap/Spinner";
import CircularProgress from "@mui/material/CircularProgress";

import Swal from "sweetalert2";
import { setCookie, getCookie, removeCookie } from "../cookie";
import DatePicker from "react-datepicker";

import "./login.css";
import axios from "axios";
import Policy from "./Policy";
import Lding from "../common/Lding";
import Ctt from "../common/Ctt";
import { useForm, Controller } from "react-hook-form";
import { Select, Input, Form } from "antd";

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import "primeicons/primeicons.css";
const { Option } = Select;

function Sin({ cont, user, sign, setUser, setCont }) {

  const {cid} = useParams()
  const [state, setState] = React.useState({
    name: "",
    email: "",
    password: "",
  });
  const {
    control,
    formState: { errors },
  } = useForm();

  const [ll, setLl] = useState(true);
  const [llb, setLlb] = useState(true);
  const [llv, setLlv] = useState(true);
  const [visible, setVisible] = useState(false);
  const [done, setDone] = useState(false);

  //   const SelectCos = (cid,cname)=>{
  //     let dcos = courses.filter((el)=> el.cid === cid)
  //     dcos.selected = true
  //     let mcos = courses.filter((el)=> el.cid !== cid)
  //     mcos.push(dcos)
  //     setCourse((prev)=>({...dcos}))
  //     setCourses((prev)=> [...mcos])
  //     setScos(!scos);

  //   }
  const [contextMenu, setContextMenu] = useState({
    xPos: "0px",
    yPos: "0px",
    show: false,
  });

  const handleRightClick = (e) => {
    // alert(e.pageX);
    e.preventDefault();

    setContextMenu({
      xPos: `${e.pageX}px`,
      yPos: `${e.pageY}px`,
      show: true,
    });
  };

  const handleClick = () => {
    setContextMenu({
      ...contextMenu,
      show: false,
    });
  };

  const [show, setShow] = useState(false);
  const [url, setUrl] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showp, setShowp] = useState(false);
  const [course, setCourse] = useState({});
  const [scos, setScos] = useState(false);
  const handleClosep = () => setShowp(false);
  const handleShowp = () => setShowp(true);
  // const [courses, setCourses] = useState([]);
  let [checkCalled, setCheck] = useState(false);
  const navigate = useNavigate();

  const handleChange = (evt) => {
    evt.preventDefault();
    const value = evt.target.value;
    setCourse({
      ...course,
      [evt.target.name]: value,
    });
  };

  const [error, setError] = useState(null);

  const [data, setNewdata] = React.useState({});
  const [vcode, setVcode] = useState("");
  const cookies = new Cookies();

  const [cookieValue, setCookieValue] = useState(cookies.get("myCookie") || "");

  let [signn, setSign] = useState(true);
  let [ss, setSs] = useState(true);
  const [verifying, setVerifying] = useState(false);
  // let [allowsign, setAllowsign] = useState(false);
  // let [lemail, setLemail] = useState('samuelonwodi@yahoo.com');
  // let [pwrd, setLpwrd] = useState('12345678');
  let [lemail, setLemail] = useState("");
  let [pwrd, setLpwrd] = useState("");
  let [wrongemail, setWrongemail] = useState("");
  // let [track, setTrack] = useState(false);
  let [sname, setSsname] = useState("");
  let [verify, setVerify] = useState(false);
  const [courses,setCourses] = useState()
  const [branches,setBranches] = useState()

  let [policy, setPolicy] = useState(false);
  function areAllKeysFilled() {
    // alert(JSON.stringify(formData));
    for (let key in formData) {
      // Check if the value is a string and has at least 2 characters
      if (formData[key].length < 2) {
        return false; // Return false if any condition fails
      }
    }
    return true; // Return true if all keys pass the check
  }

  const [nvcode, setNvcode] = useState("");

  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    cid,
    gender: "",
    brid: "",
    dob: "",
    accept: "",
    phone: "",
    date: "",
  });


  const branchOptions = branches ? branches.map((el) => ({ label: el.name, value: el.brid }))
    : [];

  const heardOptions = [
    { label: "Instagram", value: "instagram" },
    { label: "Facebook", value: "facebook" },
    { label: "Youtube", value: "youtube" },
    { label: "Advertisements (poster or handbill)", value: "poster" },
  ];
  const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, termsAccepted: e.checked });
  };
  function hasa(str) {
    // Regular expressions to check for at least one alphabet and one number
    const hasAlphabet = /[a-zA-Z]/; // Matches any letter (uppercase or lowercase)
    const hasNumber = /[0-9]/; // Matches any digit

    // Check if the string contains both an alphabet and a number
    return hasAlphabet.test(str) && hasNumber.test(str);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // alert(formData.dob);

    // alert(JSON.stringify(formData))
    let filled = areAllKeysFilled();
    const good = hasa(formData.password);

    if (!filled) {
      Swal.fire({
        title: `Error:Pls fill all input fields.`,
        text: `We can't wait to have you on board , kindly enter valid details .`,
        icon: "error",
      });
    } else if (formData.password.length < 6 || !good) {
      Swal.fire({
        title: `Error:Password is not secured.`,
        text: `password should not be less than 6 characters ,password should contain at least one letter and number .`,
        icon: "info",
      });
    } else if (filled && formData.email.includes("@")) {
      // alert("filled")
      setLlb(false);

      // Create a new FormData object
      let formDataToSend = new FormData();
      const cos = courses.find((el) => el.cid === formData.cid);

      // Iterate through the form data and append each field and value to FormData
      for (let key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      formDataToSend.append("fe", cont.fe);
      formDataToSend.append("amount", cos.price);

      try {
        await axios
          .post(`${cont.api}newsignup`, formDataToSend, {
            headers: { userid: "allowasap" },
          })
          .then((res) => {
            if (res.data.success) {
              if (res.data.na) {
                Swal.fire({
                  title: `Wow , nice try !`,

                  icon: "error",
                });
                navigate("/");
              } else if (res.data.exist) {
                Swal.fire({
                  title: `Email is in use!`,
                  text: "this email is currently in use by a student",

                  icon: "warning",
                });
              } else {
                localStorage.setItem("newpay", res.data.refd);
                window.location.href = res.data.url;
              }
            } else {
              // Dalert('pls check your network connection');
              Swal.fire({
                title: `connection error : pls check your internet connection`,
                text: `we are experiencing difficulties with your internet connection`,
                icon: "error",
              });
              setLlb(true);
            }
          });
      } catch (error) {
        // Handle error if needed
        console.error("Error:", error);
        Swal.fire({
          title: `connection error : pls check your internet connection`,
          text: `we are experiencing difficulties with your internet connection`,
          icon: "error",
        });
        //   setLlb(true);
        // Dalert('pls check your network connection');
      }
      // setLlb(true);
    } else {
      Swal.fire({
        title: `Error:Pls fill all input fields.`,
        text: `We can't wait to have you on board , kindly enter valid details .`,
        icon: "error",
      });
    }
  };
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 70);
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 10);

  // Format the date to YYYY-MM-DD
  const formattedMinDate = minDate.toISOString().split("T")[0];
  const formattedMaxDate = maxDate.toISOString().split("T")[0];

  const handleDateChange = (date) => {
    const gg = new Date(date);

    // alert(new Date(date))
    const jsDateString = gg.toDateString();
    // const jsDateString = date.toDateString();
    // alert(new Date(jsDateString));
    console.log(gg, jsDateString);
    setFormData({ ...formData, dob: jsDateString, date: gg });
  };
  const handleDateChangeb = (date) => {
    const gg = new Date(date);
    // alert(new Date(date))
    const jsDateString = date.toDateString();
    // alert(new Date(jsDateString));
    setFormData({ ...formData, dob: jsDateString, date });
  };
  const [nc,setNc] = useState(false)
    useEffect(() => {
      const Check = async () => {
        try {
          setNc(true);

          await axios.get(`${cont.api}getcoursesn`).then((res) => {
            if (res.data.success) {
              // setFirst(false);

              if (res.data.courses.length > 0) {
                //   alert(res.data.data.latestloginplayertime);
                setCourses((prev) => [...res.data.courses]);
                setBranches(res.data.branches)
                setCourse((prev) =>
                  res.data.courses.find((el) => el.cid === cid)
                );

                // setFirst(false);

                // navigate('/');
              }
            } else {
              navigate("/");
            }
          });
        } catch (error) {
          // Handle error if needed
          console.error("Error:", error);
        }
        setNc(false);
      };

      // Check if jwtt and first are truthy
      if (!checkCalled && user) {
        // Call Check() if conditions are met
        Check();

        // Set the flag to true to indicate that Check() has been called
        setCheck(!checkCalled);
      } else if (!checkCalled) {
        Check();

        // Set the flag to true to indicate that Check() has been called
        setCheck(!checkCalled);
        if (user) {
          Check();

          // Set the flag to true to indicate that Check() has been called
          setCheck(!checkCalled);
        }
      }
    }, [courses, user, checkCalled]);

  const setPolicyy = () => {
    setPolicy(false);
  };
  function cap(text) {
    if (typeof text !== "string" || text.length === 0) {
      return "";
    }
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }
  return (
    <div className="kbody">
      <MDBContainer className="my-3">
        {course ? (
          <MDBCard>
            <div className="text-center pt-2 ">
              <Link to="/" className=" text-center">
                <img
                  className="col-lg-2 col-4"
                  style={{ objectFit: "contain" }}
                  src="/images/codar-logo.png"
                  alt=""
                />

                {/* <span className="h1 fw-bold mb-0">Codar</span> */}
              </Link>
            </div>
            <MDBRow className="g-0 align-items-center">
              <MDBCol md="5" className="px-3 d-none d-md-block mb-3">
                <h2 className="text-dark">
                  {course.name} &nbsp; ({course.durationm})
                </h2>
                {/* <p className="d-inline ">{course.durationm}</p> */}
                <MDBCardImage
                  src={course.image}
                  alt="login form"
                  className="rounded-start w-100"
                />
                <h3>{cap(course.desc)}</h3>
                <h2 className="text-success">{course.dprice}</h2>
              </MDBCol>

              <MDBCol md="7">
                <MDBCardBody className="d-flex flex-column col-12">
                  <div className="p-fluid row px-2 col-md-12 col-12 mx-auto pt-3">
                    <h2>
                      Complete{" "}
                      <h2 className="text-success d-inline">{course.name} </h2>{" "}
                      Registeration
                    </h2>

                    <div className="p-field col-md-6 col-lg-4 col-12">
                      <label htmlFor="fname">First name</label>
                      <InputText
                        id="fname"
                        name="fname"
                        className=""
                        value={formData.fname}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="p-field col-md-6 col-lg-4 col-12">
                      <label htmlFor="lname">Last name</label>
                      <InputText
                        id="lname"
                        name="lname"
                        className=""
                        value={formData.lname}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="p-field col-md-6 col-lg-4 col-12">
                      <label htmlFor="email">Email</label>
                      <InputText
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="p-field col-md-6 col-lg-4 col-12">
                      <label htmlFor="phone">Phone</label>
                      <InputText
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="p-field  col-md-6 col-lg-4 col-12">
                      <label htmlFor="password">Password</label>
                      <Password
                        id="password"
                        name="password"
                        toggleMask
                        value={formData.password}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="p-field  col-md-6 col-lg-4 col-12">
                      <label htmlFor="gender">Gender</label>
                      <Dropdown
                        id="gender"
                        name="gender"
                        value={formData.gender}
                        options={genderOptions}
                        onChange={handleInputChange}
                        placeholder="Select Gender"
                      />
                    </div>
                    <div className="p-field  col-md-6 col-lg-4 col-12">
                      <label htmlFor="cid">Selected Course</label>
                      <InputText
                        id="cid"
                        name="cid"
                        style={{ fontSize: "15px" }}
                        value={course.name}
                        disabled
                      />
                    </div>

                    <div className="p-field  col-md-6 col-lg-4 col-12">
                      <label htmlFor="dob">Date of Birth</label>

                      <Controller
                        name="dob"
                        control={control}
                        rules={{ required: "Date is required" }}
                        render={({ field }) => (
                          <input
                            type="date"
                            placeholder="Select a date"
                            onChange={(e) => handleDateChange(e.target.value)}
                            format="MMMM D, YYYY"
                            min={formattedMinDate}
                            max={formattedMaxDate}
                          />
                        )}
                      />
                    </div>
                    {branchOptions ? (
                      <div className="p-field  col-md-6 col-lg-4 col-12">
                        <label htmlFor="brid">Select Location</label>
                        <Dropdown
                          id="brid"
                          name="brid"
                          value={formData.brid}
                          options={branchOptions}
                          onChange={handleInputChange}
                          placeholder="Prefered Location"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <div className="p-field  col-md-6 col-lg-4 col-12">
                      <label htmlFor="heard">Heard us from ?.</label>
                      <Dropdown
                        id="heard"
                        name="heard"
                        value={formData.heard}
                        options={heardOptions}
                        onChange={handleInputChange}
                        placeholder="where did you get to hear about us"
                      />
                    </div> */}
                    <Dialog
                      className="col-md-6 col-10 text-dark"
                      // style={{ width: "90%", maxWidth: "600px" }}
                      header={`CODAR INSTITUTE`}
                      visible={visible}
                      // style={{ width: "50vw" }}
                      onHide={() => {
                        if (!visible) return;
                        setVisible(false);
                      }}
                      closeIcon={<i className="pi pi-times"></i>}
                    >
                      <div>
                        {/* <h2>Terms and conditions</h2> */}
                        <div className="row">
                          {policy ? (
                            <div>
                              <Policy setPolicyy={setPolicyy} />
                            </div>
                          ) : (
                            <div
                              id="terms-popup"
                              style={{
                                maxWidth: "600px",
                                padding: "20px",
                                borderRadius: "10px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <h2
                                style={{
                                  textAlign: "center",
                                  color: " #333",
                                }}
                              >
                                Terms and Conditions
                              </h2>
                              <h5 className="text-dark">
                                Welcome to Codar Institute! These terms and
                                conditions outline the rules and regulations for
                                the use of our services, courses, and website.
                              </h5>

                              <h3 style={{ color: "#333" }}>
                                1. Acceptance of Terms
                              </h3>
                              <h5>
                                By accessing or using any part of our website or
                                enrolling in any of our courses, you agree to
                                comply with and be bound by these terms and
                                conditions. If you disagree with any part of the
                                terms, please do not use our services.
                              </h5>

                              <h3 style={{ color: "#333" }}>
                                2. Course Enrollment and Payment
                              </h3>
                              <h5>
                                Enrollment in our courses is subject to
                                availability and receipt of the applicable
                                course fees. All fees must be paid in full prior
                                to the start of the course. Once the course fee
                                is paid, it is non-refundable except as
                                specified in our Refund Policy.
                              </h5>

                              <h3 style={{ color: "#333" }}>
                                3. Intellectual Property Rights
                              </h3>
                              <h5>
                                All content provided in our courses, including
                                but not limited to text, images, videos, and
                                software, is the property of Codar Institute or
                                its content suppliers and is protected by
                                copyright laws. You may not reproduce,
                                distribute, or create derivative works from any
                                of the content without our express written
                                permission.
                              </h5>

                              <h3 style={{ color: "#333" }}>
                                4. User Conduct and Responsibilities
                              </h3>
                              <h5>
                                As a student of Codar Institute, you are
                                expected to maintain a high standard of academic
                                integrity. Plagiarism, cheating, and other forms
                                of academic dishonesty are strictly prohibited
                                and may result in expulsion from the course
                                without a refund.
                              </h5>

                              <h3 style={{ color: "#333" }}>
                                5. Privacy Policy
                              </h3>
                              <h5>
                                Your privacy is important to us. We are
                                committed to protecting your personal
                                information. Please review our{" "}
                                <span
                                  className="d-inline cpp"
                                  onClick={() => setPolicy(true)}
                                  style={{ color: "#007bff" }}
                                >
                                  Privacy Policy
                                </span>{" "}
                                for more details on how we collect, use, and
                                safeguard your data.
                              </h5>

                              <h3 style={{ color: "#333" }}>
                                6. Limitation of Liability
                              </h3>
                              <h5>
                                Codar Institute shall not be held liable for any
                                direct, indirect, incidental, or consequential
                                damages resulting from the use or inability to
                                use our services or courses, even if we have
                                been advised of the possibility of such damages.
                              </h5>

                              <h3 style={{ color: "#333" }}>
                                7. Amendments to Terms
                              </h3>
                              <h5>
                                Codar Institute reserves the right to amend
                                these terms and conditions at any time. We will
                                notify you of any changes via email or through a
                                notice on our website. Continued use of our
                                services after such changes shall constitute
                                your acceptance of the new terms.
                              </h5>

                              <h3 style={{ color: "#333" }}>
                                8. Governing Law
                              </h3>
                              <h5>
                                These terms and conditions are governed by the
                                laws of The federal Republic of Nigeria, and any
                                disputes arising out of or in connection with
                                these terms shall be subject to the exclusive
                                jurisdiction of the courts of The federal
                                Republic of Nigeria.
                              </h5>

                              <h6
                                style={{ textAlign: "center", color: "#555" }}
                              >
                                By clicking "Accept", you acknowledge that you
                                have read, understood, and agree to be bound by
                                these terms and conditions.
                              </h6>

                              <div className="mt-2 text-center">
                                <button
                                  onClick={() => {
                                    setFormData((prev) => ({
                                      ...prev,
                                      accept: "accept",
                                    }));

                                    setVisible(false);
                                  }}
                                  className="mt-2 text-center"
                                >
                                  Accept
                                </button>{" "}
                                &nbsp; &nbsp; &nbsp;
                                <button
                                  onClick={() => {
                                    setVisible(false);
                                  }}
                                  className="mt-2 text-center"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Dialog>

                    <span className="p-field-checkbox align-items-center">
                      <div>
                        {/* <Checkbox
                          inputId="terms"
                          checked={formData.accept === "accept" ? true : false}
                       
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFormData({ ...formData, accept: "accept" });
                            } else {
                              setFormData({ ...formData, accept: "reject" });
                            }
                          }}
                        /> */}
                      </div>

                      <div className="text-center d-inline">
                        <div className="col-1 text-start">
                          <input
                            id="terms"
                            className=" "
                            inputId="terms"
                            checked={
                              formData.accept === "accept" ? true : false
                            }
                            // checked={formData.termsAccepted}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFormData({
                                  ...formData,
                                  accept: "accept",
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  accept: "reject",
                                });
                              }
                            }}
                            type="checkbox"
                            name=""
                          />
                        </div>
                        <label htmlFor="terms">
                          I accept the{" "}
                          <span
                            className="d-inline cpp text-danger"
                            onClick={() => {
                              setVisible(true);
                            }}
                          >
                            terms
                          </span>{" "}
                          and conditions
                        </label>
                      </div>
                    </span>

                    {!(formData.accept === "accept") && (
                      <Message
                        severity="error"
                        text="kindly read and accept our terms and conditions"
                      />
                    )}

                    {!llb ? (
                      <div className=" col-12 mt-3 col-lg-6 col-md-6 mx-auto position-relative">
                        {/* <CircularProgress className="text-light blinking-text" /> */}
                        <Lding
                          className="text-center"
                          animation="border"
                          variant="light"
                        />
                      </div>
                    ) : (
                      <button
                        onClick={handleSubmit}
                        className="button rounded col-12 mt-3 col-lg-6 col-md-6 mx-auto position-relative"
                      >
                        Sign Up
                      </button>
                    )}

                    {/* <Button label="Sign Up" /> */}
                  </div>

                  {/* <h5 className="mb-5 pb-lg-2" style={{ color: "#393f81" }}>
                      login ?{" "}
                      <Link to="/login" style={{ color: "#393f81" }}>
                        login here
                      </Link>
                    </h5> */}
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBCard>
        ) : (
          <div className="text-center">
            <Lding />
          </div>
        )}
      </MDBContainer>
    </div>
  );
}

export default Sin;
