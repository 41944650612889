import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Ctt from "../common/Ctt";
import { Dialog } from "primereact/dialog";
import Card from "react-bootstrap/Card";
import { Chip } from "primereact/chip";
import { Divider } from "primereact/divider";
import { Password } from "primereact/password";
import Swal from "sweetalert2";
import axios from "axios"
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import Button from "@mui/material/Button";
const Aheader = ({
  academic,
  trans,
  users,
  ls,
  user,
  acourse,
  yescos,
  actions,
  db,cont,
  setUser,run,
  courses,
}) => {
  const [snack, setSnack] = useState("");
  const navigate = useNavigate();
  
  const dt = new Date();
  const dd = dt.getMonth();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  function hasa(str) {
    // Regular expressions to check for at least one alphabet and one number
    const hasAlphabet = /[a-zA-Z]/; // Matches any letter (uppercase or lowercase)
    const hasNumber = /[0-9]/; // Matches any digit

    // Check if the string contains both an alphabet and a number
    return hasAlphabet.test(str) && hasNumber.test(str);
  }
  const Updatep = async()=>{
    const uup = user.userid + "_" + newp
  
    try {
          await axios
            .get(`${cont.api}student/changepassword/${uup}`, {
              headers: { userid: user.userid },
            })
            .then((res) => {
              if (res.data.success) {
               
             
                  setUser(res.data.user);  
                  setVp(false)                
                  setNp(false)                
                  setOldp("")                
                  setNewp("")                

                  Swal.fire({
                    title: "password update was successful !",
                    text: "password updated successfully ! !",
                    icon: "success",
                  });
             
              
              } else {
                Swal.fire({
                  title: "Action failed!",
                  text: "something went wrong doing this .",
                  icon: "error",
                });
              }
            });
        } catch (err) {
          Swal.fire({
            title: "I'm afraid you can't perform this operation now !",
            text: "Pls check your internet network ! !",
            icon: "caution",
          });
        }
  }
  const month = months[dd];
  function setItemWithExpiry(key, value, ttl) {
    const now = new Date();

    // `ttl` is time to live in milliseconds (5 minutes = 5 * 60 * 1000)
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };

    localStorage.setItem(key, JSON.stringify(item));
  }
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ ...newState, open: true });
  };
  const Logout = () => {
    localStorage.removeItem("userid");
    setSnack("Account signed out !");
    setUser(() => null);

    handleClick({ vertical: "top", horizontal: "center" });
    // setSnack('');

    navigate("/");
  };
  const [vp,setVp] = useState(false)
  const [oldp,setOldp] = useState("")
  const [newp,setNewp] = useState("")
  const [np, setNp] = useState(false)
  const [load, setLoad] = useState(false);
  
  
  // const navigate = useNavigate();

  // alert(courses.length)
  return (
    // <div className=" position-sticky tpp ">
    <Navbar
      style={{ maxHeight: "12vh" }}
      expand="lg"
      className="position-sticky tpp bg-body-tertiary bgtdran boxsha"
    >
      <Dialog
        className="col-md-6 col-10"
        // style={{ width: "90%", maxWidth: "600px" }}
        header={`${!np ? `What is your current Password ?` : `Enter New password`}`}
        visible={vp}
        // style={{ width: "50vw" }}
        onHide={() => {
          if (!vp) return;
          setVp(false);
          setNp(false);
          setNewp("");
          setOldp("");
        }}
        closeIcon={<i className="pi pi-times"></i>}
      >
        <div>
          <div className="row">
            {np ? (
              <div className="col-12 mb-1">
                {/* <Chip label="Change Password" icon="pi pi-padlock" /> */}
                <Divider />

                <div className="col-12 text-center ">
                  <div className=" col-10 mx-auto">
                    <img
                      className="well row col-md-6 col-lg-6 col-10 mx-auto"
                      src="/gifs/pwrd.gif"
                      alt=""
                    />

                    <Password
                      value={newp}
                      className="text-center px-1 row col-md-6 col-lg-6 col-12 mx-auto"
                      placeholder="enter new password"
                      feedback={false}
                      tabIndex={1}
                      toggleMask
                      onChange={(e) => setNewp(e.target.value)}
                    />
                    <small className="text-danger">
                      password must contain at least one alphabet and number
                    </small>
                  </div>
                  <Divider />

                  {newp.length > 5 && hasa(newp) && newp !== oldp ? (
                    <button onClick={Updatep} className="button">
                      Update password
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              <div className="col-12 mb-1">
                {/* <Chip label="Change Password" icon="pi pi-padlock" /> */}
                <Divider />

                <div className="col-12 text-center">
                  <img
                    className="well row col-md-6 col-lg-6 col-8 mx-auto"
                    src="/gifs/pwrd.gif"
                    alt=""
                  />
                  <Password
                    value={oldp}
                    className="text-center"
                    placeholder="enter current password"
                    feedback={false}
                    tabIndex={1}
                    toggleMask
                    onChange={(e) => setOldp(e.target.value)}
                  />
                  <Divider />

                  {oldp.length > 5 ? (
                    <button
                      onClick={() => {
                        if (oldp === user.pwrdb) {
                          setNp(true);
                        } else {
                          setOldp("");
                          setVp(false);
                          Swal.fire({
                            title: "Error : Incorrect password !",
                            text: "you just entered an incorrect current password !",
                            icon: "error",
                          });
                        }
                      }}
                      className="button"
                    >
                      Verify
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Dialog>
      <Container fluid className="">
        <Link
          to="/studentdashboard"
          className="navbar-brand  align-items-center  "
        >
          <Nav>
            <img
              style={{ border: "none", outline: "none" }}
              className="logom mb-1"
              src="/images/tile.png"
              alt=""
            />
            {/* COdar */}
          </Nav>
        </Link>
        <Navbar.Toggle aria-controls="navbarScroll" className=" navsc" />
        <Navbar.Collapse
          id="navbarScroll"
          className="justify-content-end text-end fw-5 text-responsive workonn"
        >
          <Nav
            // style={{ maxHeight: "12vh" }}
            className="me-autjo align-items-center   text-end d-flex my-1 my-lg-0"
            // style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Link
              to="/studentdashboard"
              className={`${db ? "active" : ""} nav-item nav-link`}
            >
              <Nav
                className={`${db ? "active text-danger" : ""} fw-3 text-responsive`}
                style={{ fontWeight: "700", border: "none", outline: "none" }}
              >
                Dashboard
              </Nav>
            </Link>

            {user.restricted ? (
              <Link
                onClick={() => run()}
                className={`${academic ? "active" : ""} nav-item nav-link`}
              >
                <Nav
                  className={`${academic ? "active text-danger" : "fw-3"} text-responsive`}
                  style={{
                    fontWeight: "700",
                    color: academic ? "red" : "",
                    border: "none",
                    outline: "none",
                  }}
                >
                  Academics
                </Nav>
              </Link>
            ) : (
              <Link
                to={`/academics/${user.bid}`}
                className={`${academic ? "active" : ""} nav-item nav-link`}
              >
                <Nav
                  className={`${academic ? "active text-danger" : "fw-3"} text-responsive`}
                  style={{
                    fontWeight: "700",
                    color: academic ? "red" : "",
                    border: "none",
                    outline: "none",
                  }}
                >
                  Academics
                </Nav>
              </Link>
            )}

            {user.allowcc ? (
              <a
                href={`${cont.ccfe}#/uhome/${user.ccode + "_" + user.userid}`}
                className={`${acourse ? "active" : ""} nav-item nav-link`}
              >
                <Ctt id="tooltip-cart" tooltip="Codar Coins">
                  <Nav
                    className={`${courses ? "" : "fw-3"} text-responsive`}
                    style={{ fontWeight: "700", color: acourse ? "red" : "" }}
                  >
                    CC
                  </Nav>
                </Ctt>
              </a>
            ) : (
              ""
            )}

            <NavDropdown
              title={
                <Link
                  style={{ fontWeight: "900" }}
                  className=" align-items-center text-success font-bold text-responsive"
                >
                  {user.name}{" "}
                  {/* {user.image ? (
                      <img
                        src={user.image}
                        alt="hgfgh"
                        className="d-md-block prof"
                      />
                    ) : (
                      ""
                    )} */}
                </Link>
              }
              id="navbarScrollingDropdown"
              className="makewhite"
              style={{ fontWeight: "900" }}
            >
              <NavDropdown.Item style={{ zIndex: 50 }}>
                <Nav onClick={() => setVp(true)}>Change password</Nav>
              </NavDropdown.Item>

              <NavDropdown.Item style={{ zIndex: 50 }}>
                <Nav
                  onClick={Logout}
                  className="text-danger rounded align-items-center fs-4 py-2 px-2"
                >
                  <i className="fa fa-sign-out text-danger"></i> &nbsp; Logout
                </Nav>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    // </div>
  );
};

export default Aheader;
