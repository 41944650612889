import React from 'react'

const Lding = () => {
  return (
    <div
      className="text-center py-2  align-items-center jcent"

    >
      <div className=''>
        <img className="col-2 mx-auto text-danger mt-5" src="/gifs/red.gif" alt="" />
      </div>
    </div>
  );
}

export default Lding
