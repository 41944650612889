import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  Suspense,
} from "react";
import axios from "axios";
import Swal from "sweetalert2";

import "animate.css"; // Make sure you include the animate.css library for animations
import "@fortawesome/fontawesome-free/css/all.css";


import {
  HashRouter as Router,
  useLocation,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Cookies from "js-cookie";
import "react-bootstrap";

import { useUser } from "../src/components/usercontext";
import { setCookie, getCookie, removeCookie } from "./components/cookie";
import useMeasure from "react-use-measure";
import { useTrail, animated } from "@react-spring/web";
import AOS from "aos";
import "aos/dist/aos.css";
import { ScrollTop } from "primereact/scrolltop";
import codarr from "./codarbg.png";


// Static imports (if they're small or necessary right away)
import Sc from "./components/common/Sc";
import Lding from "./components/common/Lding";
import Ldingb from "./components/common/Ldingb";
import Home from "./components/home/Home";
import Talk from "./components/common/Talk";
import Marque from "./components/home/marque";
import Conn from "./components/common/Connection";
import Ssin from "./components/home/Ssin";


import "@fortawesome/fontawesome-free/css/all.css";
import "animate.css";
import styles from "./styles.module.css";
import "semantic-ui-css/semantic.min.css";
import { PrimeReactProvider } from "primereact/api";

import "primereact/resources/themes/saga-blue/theme.css"; // Choose your theme
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";


import "./res.css";


// Lazy imports for components that can be loaded later
// const Home = React.lazy(() => import("./components/home/Home"));
const Admin = React.lazy(() => import("./components/admin/Dashboard"));
const Acourse = React.lazy(() => import("./components/admin/Course"));
const Scourses = React.lazy(() => import("./components/sales/Courses"));
const Controls = React.lazy(() => import("./components/admin/Controls"));
const Curri = React.lazy(() => import("./components/home/Curri"));
const Scurri = React.lazy(() => import("./components/student/Curri"));
const Acourses = React.lazy(() => import("./components/admin/Acourses"));
const Courseallstudents = React.lazy(
  () => import("./components/admin/course/Allstudents")
);
const Coursenewstudents = React.lazy(
  () => import("./components/admin/course/Newstudents")
);
const Atopics = React.lazy(() => import("./components/admin/Topics"));
const Acurri = React.lazy(() => import("./components/admin/batch/Curri"));
const Student = React.lazy(() => import("./components/admin/batch/Student"));
const Actions = React.lazy(() => import("./components/admin/Actions"));
const Teacherdb = React.lazy(() => import("./components/mentor/Dashboard"));
const Asales = React.lazy(() => import("./components/admin/Sales"));
const About = React.lazy(() => import("./components/home/About"));
const Learnmore = React.lazy(() => import("./components/home/Learnmore"));
const Workforce = React.lazy(() => import("./components/home/Workforce"));
const Online = React.lazy(() => import("./components/home/Online"));
const Careers = React.lazy(() => import("./components/home/Careers"));
const Atrans = React.lazy(() => import("./components/admin/Atrans"));
const Adtrans = React.lazy(() => import("./components/admin/Transactions"));
const Salestrans = React.lazy(() => import("./components/sales/Transactions"));
const Madtrans = React.lazy(() => import("./components/admin/Mtransactions"));
const Pending = React.lazy(() => import("./components/admin/Pending"));
const Allleads = React.lazy(() => import("./components/admin/Allleads"));
const Mleads = React.lazy(() => import("./components/admin/Mleads"));
const Allstuds = React.lazy(() => import("./components/admin/Allstuds"));
const Mstuds = React.lazy(() => import("./components/admin/Mstuds"));
const Studentdb = React.lazy(() => import("./components/student/Dashboard"));
const Smtrans = React.lazy(() => import("./components/sales/Mtransactions"));
const Sallleads = React.lazy(() => import("./components/sales/Allleads"));
const Smleads = React.lazy(() => import("./components/sales/Mleads"));
const Sallstuds = React.lazy(() => import("./components/sales/Allstuds"));
const Smstuds = React.lazy(() => import("./components/sales/Mstuds"));
const Mentors = React.lazy(() => import("./components/admin/Mentors"));
const Courses = React.lazy(() => import("./components/home/Courses"));
const Watch = React.lazy(() => import("./components/home/Watch"));
const Coursesu = React.lazy(() => import("./components/home/Coursesu"));
const Course = React.lazy(() => import("./components/admin/Course"));
const Prj = React.lazy(() => import("./components/home/Prj"));
const Gallery = React.lazy(() => import("./components/home/Galleryy"));
const Jobs = React.lazy(() => import("./components/home/Jobs"));
const Sform = React.lazy(() => import("./components/home/Entry"));
const Mentordashboard = React.lazy(
  () => import("./components/mentor/Dashboard")
);
const Admins = React.lazy(() => import("./components/admin/Admins"));
const Admindashboard = React.lazy(() => import("./components/admin/Dashboard"));
const Salesdashboard = React.lazy(() => import("./components/sales/Dashboard"));
const Adminbatches = React.lazy(() => import("./components/admin/Batches"));
const TeacherCurri = React.lazy(() => import("./components/mentor/Curri"));
const Branches = React.lazy(() => import("./components/admin/Branches"));
const Tcourses = React.lazy(() => import("./components/mentor/Courses"));

// import "primereact/resources/themes/lara-light-cyan/theme.css";

const fast = { tension: 1200, friction: 40 };
const slow = { mass: 10, tension: 200, friction: 50 };
const trans = (x, y) => `translate3d(${x}px,${y}px,0) translate3d(-50%,-50%,0)`;

export const GlobalContext = createContext();

function App() {
  const navigate = useNavigate();

  const { user, setUser } = useUser();
  const [loadd, setLoading] = useState(true);

  const codar = "url(/girls/codarbg.png)";
  const dum = {
    name: "mikptech",
    fname: "Mikptech Innovations",

    phone: "+234 8130757003",
    email: "info@mikptech.com",
    expiresin: 5,
    idle: 5,
    tableitems: 10,
    cc: false,
    vl: "",
    codar: codarr,
    cmail: "careers@mikptech.com.ng",

    // api: "http://localhost:1000/",
    // fe: "http://localhost:3000/",
    // ccfe: "http://localhost:3001/",
    api: "https://mikpapi.onrender.com/",

    fe: "https://mikptech.com.ng/",
    ccfe: "https://codarcoins.netlify.app/",
  };

  const [cont, setCont] = useState({ ...dum });
  const [coses, setCoses] = useState([]);
  const [global, setGlobal] = useState(cont);

  // const [user, setUser] = useState(false);

  const hasExpired = () => {
    const nd = new Date();
    const userid = localStorage.getItem("user");
    const expiresin = localStorage.getItem("expires");

    if (userid && expiresin) {
      if (nd > new Date(expiresin)) {
        // Token has expired
        // alert('expired ' + expiresin);
        Swal.fire({
          title: `Time-out`,
          text: `pls login again `,
          icon: "info",
          position: "top-end",

          showConfirmButton: false,
          timer: 2500,
        });
        localStorage.removeItem("user");
        localStorage.removeItem("expires");
        setUser(null);
        // navigate('/') // Uncomment if you have a navigation function
        return true;
      } else {
        // Token is still valid
        return false;
      }
    }

    // If no user or expiresin is found
    return true;
  };

  const updateUser = (newUser) => {
    setUser(newUser);
    // alert(newUser.idle)
    // setCont((prev)=>({...prev,expiresin:newUser.idle}))
  };

  const games = [
    {
      name: "Pick6",
      image: "/images/dice.gif",
      to: "single",
      gameid: "tr14246867550852",
      one: 0,
      two: 0,
      three: 0,
      four: 1.2,
      five: 1.5,
      six: 2.0,
      about:
        "Welcome to Pick6, where your fortunes await at the roll of the dice! Step into a world of thrilling uncertainty, where every click brings you closer to uncovering your fate. In this captivating game of chance, players hold their breath as they watch the dice spin, anticipation building with each passing moment. Will luck be on your side, revealing a winning combination that propels you to victory? Or will you face the challenge head-on, embracing the exhilarating risk that comes with every roll? Join us in the excitement of Pick6, where the journey is as exhilarating as the destination. Dare to roll the dice and discover what fate has in store for you!",
    },
    {
      name: "Team Quest",
      image: "/images/ballers.gif",
      // image: '/images/droll.gif',
      to: "/double",
      gameid: "tr1323744346786",
      about:
        "Step into the world of Adventures, where every roll counts towards victory! In this adrenaline-fueled game of chance, players embark on a thrilling journey of dice rolls, each one bringing them closer to glory. With only ten rolls at their disposal, every decision becomes crucial as they strategize and aim for the highest possible score. Will you play it safe and secure steady points, or risk it all for the chance of a game-changing roll? As the tension mounts with each toss of the dice, the excitement reaches its peak as players race against time to clinch the title of champion. Join us in After 10, where the quest for victory awaits with every roll of the dice!",
    },
  ];
  const [courses, setCourses] = useState();

  const location = useLocation();
  const { state } = location;
  // const [trans, setTrans] = useState([]);
  const [cos, setCos] = useState([]);

  const [trail, api] = useTrail(3, (i) => ({
    xy: [0, 0],
    config: i === 0 ? fast : slow,
  }));
  const [ref, { left, top }] = useMeasure();

  const handleMouseMove = (e) => {
    api.start({ xy: [e.clientX - left, e.clientY - top] });
  };
  useEffect(() => {
    const handleActivity = () => {
      // Retrieve the cookie value
      const userid = getCookie("userid");
      if (user && userid) {
        // setUser(() => user);
        setCont((prev) => ({
          ...prev,
          expiresin: user.idle,
          idle: user.idle,
          tableitems: user.tableitems,
        }));

        setCookie("userid", user.userid, cont.idle);
      } else if (user && !userid) {
        setUser(() => null);
        Swal.fire({
          title: `active time expired !`,
          text: `Login again : You have been inactive for too long`,
          icon: "info",
          // position: 'top-end',

          showConfirmButton: false,
          timer: 5500,
        });
        navigate("/");
      }
      console.log("User:", user);
    };
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
    };
  });
  useEffect(() => {
    // Function to fetch the courses using Axios
    const fetchCourses = async () => {
      try {
        await axios.get(`${cont.api}getcoursesn`).then((res) => {
          if (res.data.success) {
            let coses = res.data.courses;
            setLoading(false);

            setCont((prev) => ({
              ...prev,
              online: res.data.online,
              coses: coses ? coses : [],
            }));

            if (coses.length > 0) {
              setCoses(res.data.courses);
            } else {
              // alert("in here")
            }
          } else {
            // alert("noo");

            navigate("/");
          }
        });
      } catch (error) {
        console.error("Error fetching the courses", error);
      }
    };

    fetchCourses();

    // Empty dependency array ensures the effect runs only once
  }, []);

  return (
    <PrimeReactProvider>
      <GlobalContext.Provider value={cont}>
        <Suspense
          fallback={
            <div>
              {" "}
              <Ldingb />
            </div>
          }
        >
          <div
            className={`${styles.container} cbg`}
            style={{
              height: "120vh",
              background: codar,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div>
              <Sc />

              <Routes>
                <Route
                  path="/tcourses"
                  element={
                    user && user.isTeacher ? (
                      <Tcourses
                        cont={cont}
                        games={games}
                        courses={cos}
                        setUser={updateUser}
                        user={user}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/enterbatch/:bid"
                  element={
                    user && user.isTeacher ? (
                      <TeacherCurri
                        cont={cont}
                        games={games}
                        courses={cos}
                        setUser={updateUser}
                        user={user}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/gallery"
                  element={
                    <Gallery
                      cont={cont}
                      
                    />
                  }
                />
                <Route
                  path="/teacherdashboard"
                  element={
                    <Teacherdb
                      cont={cont}
                      games={games}
                      courses={cos}
                      setUser={updateUser}
                      user={user}
                    />
                  }
                />
                <Route
                  path="/studentdashboard"
                  element={
                    <Studentdb
                      cont={cont}
                      games={games}
                      courses={cos}
                      setUser={updateUser}
                      user={user}
                    />
                  }
                />
                <Route
                  path="/success/:userid"
                  element={
                    <Studentdb
                      cont={cont}
                      games={games}
                      courses={cos}
                      setUser={updateUser}
                      user={user}
                      success={true}
                    />
                  }
                />
                <Route
                  path="/straightsign/:cid"
                  element={
                    <Ssin
                      cont={cont}
                      games={games}
                      courses={cos}
                      setUser={updateUser}
                    />
                  }
                />
                <Route
                  path="/user/:userid"
                  element={
                    <Coursesu
                      cont={cont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                    />
                  }
                />
                <Route
                  path="/adminpendinggettransactions"
                  element={
                    user && user.admin ? (
                      <Adtrans cont={cont} user={user} setUser={updateUser} />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/allleads"
                  element={
                    user && user.admin ? (
                      <Allleads cont={cont} user={user} setUser={updateUser} />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/salesdashboard"
                  element={
                    user && user.isSales ? (
                      <Salesdashboard
                        recent={true}
                        cont={cont}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/snewleads"
                  element={
                    user && user.isSales ? (
                      <Smleads
                        recent={true}
                        cont={cont}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/sallstuds"
                  element={
                    user && user.isSales ? (
                      <Sallstuds
                        recent={true}
                        cont={cont}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/sallleads"
                  element={
                    user && user.isSales ? (
                      <Sallleads
                        recent={true}
                        cont={cont}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/newleads"
                  element={
                    user && user.admin ? (
                      <Mleads
                        recent={true}
                        cont={cont}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                {/* <Route
                path="/newleads"
                element={
                  user && user.admin ? (
                    <Allleads recent={true} cont={cont} user={user} setUser={updateUser} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              /> */}
                <Route
                  path="/allstuds"
                  element={
                    user && user.admin ? (
                      <Allstuds cont={cont} user={user} setUser={updateUser} />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                {/* <Route
                path="/newstuds"
                element={
                  user && user.admin ? (
                    <Allstuds cont={cont} recent={true} user={user} setUser={updateUser} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              /> */}
                <Route
                  path="/newstuds"
                  element={
                    user && user.admin ? (
                      <Mstuds
                        cont={cont}
                        recent={true}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/admingettransactions"
                  element={
                    user && user.admin ? (
                      <Adtrans cont={cont} user={user} setUser={updateUser} />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/salesrecenttransactions"
                  element={
                    user && user.isSales ? (
                      <Smtrans cont={cont} user={user} setUser={updateUser} />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/salestransactions"
                  element={
                    user && user.isSales ? (
                      <Salestrans
                        cont={cont}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/adminrecenttransactions"
                  element={
                    user && user.admin ? (
                      <Madtrans
                        recent={true}
                        cont={cont}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/pending"
                  element={
                    user && user.admin ? (
                      <Pending
                        recent={true}
                        cont={cont}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/branches"
                  element={
                    user && user.admin ? (
                      <Branches cont={cont} user={user} setUser={updateUser} />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/adminbatches/:cid"
                  element={
                    user && user.admin ? (
                      <Adminbatches
                        cont={cont}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/getstud/:string"
                  element={
                    user && user.admin ? (
                      <Student cont={cont} user={user} setUser={updateUser} />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/academics/:bid"
                  element={
                    user && user.isStudent ? (
                      <Scurri cont={cont} user={user} setUser={updateUser} />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/curri/:bid"
                  element={
                    user && user.admin ? (
                      <Acurri cont={cont} user={user} setUser={updateUser} />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/courseallstudents/:cid"
                  element={
                    user && user.admin ? (
                      <Courseallstudents
                        cont={cont}
                        games={games}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/coursenewstudents/:cid"
                  element={
                    user && user.admin ? (
                      <Coursenewstudents
                        cont={cont}
                        games={games}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/controls"
                  element={
                    user && user.host ? (
                      <Controls cont={cont} user={user} setUser={updateUser} />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/actions"
                  element={
                    user && user.host ? (
                      <Actions
                        cont={cont}
                        games={games}
                        user={user}
                        dmy={true}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/dmyactions"
                  element={
                    user && user.host ? (
                      <Actions
                        cont={cont}
                        games={games}
                        user={user}
                        dmy={true}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/mandyactions"
                  element={
                    user && user.host ? (
                      <Actions
                        cont={cont}
                        games={games}
                        user={user}
                        mandy={true}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/sform"
                  element={
                    <Sform
                      cont={cont}
                      setCont={setCont}
                      games={games}
                      user={user}
                      sign={true}
                      setUser={updateUser}
                      courses={cos}
                    />
                  }
                />
                <Route
                  path="/mentordashboard"
                  element={
                    user && user.isTeacher ? (
                      <Mentordashboard
                        cont={cont}
                        games={games}
                        user={user}
                        setUser={updateUser}
                        courses={cos}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/admindashboard"
                  element={
                    user && user.admin ? (
                      <Admindashboard
                        cont={cont}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/login"
                  element={
                    <Sform
                      cont={cont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                      courses={cos}
                    />
                  }
                />
                <Route
                  path="/careers"
                  element={
                    <Careers
                      cont={cont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                      courses={cos}
                    />
                  }
                />
                <Route
                  path="/online"
                  element={
                    <Online
                      cont={cont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                      courses={cos}
                    />
                  }
                />
                <Route
                  path="/learnmore"
                  element={
                    <Learnmore
                      cont={cont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                      courses={cos}
                    />
                  }
                />
                <Route
                  path="/workforce"
                  element={
                    <Workforce
                      cont={cont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                      courses={cos}
                    />
                  }
                />
                <Route
                  path="/"
                  element={
                    <Home
                      cont={cont}
                      setCont={setCont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                      courses={courses}
                      setCourses={setCourses}
                      exact
                    />
                  }
                />
                <Route
                  path="/sorry"
                  element={
                    <Home
                      cont={cont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                      courses={courses}
                      setCourses={setCourses}
                      sorry={true}
                      exact
                    />
                  }
                />
                <Route
                  path="/prj"
                  element={
                    <Prj
                      cont={cont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                      courses={courses}
                      setCourses={setCourses}
                    />
                  }
                />
                <Route
                  path="/jobs"
                  element={
                    cont ? (
                      <Jobs
                        cont={cont}
                        games={games}
                        user={user}
                        setUser={updateUser}
                        courses={courses}
                        setCourses={setCourses}
                      />
                    ) : (
                      <Navigate to="/sorry" />
                    )
                  }
                />
                <Route
                  path="/courses"
                  element={
                    <Courses
                      cont={cont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                    />
                  }
                />
                <Route
                  path="/admin"
                  element={
                    user && user.admin ? (
                      <Admin
                        cont={cont}
                        games={games}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/sorry" />
                    )
                  }
                />
                <Route
                  path="/acourse/:cid"
                  element={
                    user && user.admin ? (
                      <Acourse cont={cont} user={user} setUser={updateUser} />
                    ) : (
                      <Navigate to="/sorry" />
                    )
                  }
                />
                <Route
                  path="/admintopics/:cid"
                  element={
                    user && user.admin ? (
                      <Atopics cont={cont} user={user} setUser={updateUser} />
                    ) : (
                      <Navigate to="/sorry" />
                    )
                  }
                />
                <Route
                  path="/atrans"
                  element={
                    user && user.admin ? (
                      <Atrans
                        cont={cont}
                        games={games}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/sorry" />
                    )
                  }
                />
                <Route
                  path="/asales"
                  element={
                    user && user.admin ? (
                      <Asales
                        cont={cont}
                        games={games}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/sorry" />
                    )
                  }
                />

                <Route
                  path="/course"
                  element={
                    state ? (
                      <Course
                        cont={cont}
                        games={games}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/sorry" />
                    )
                  }
                />
                <Route
                  path="/frompaystack/:ref"
                  element={
                    <Curri
                      cont={cont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                    />
                  }
                />
                <Route
                  exact
                  path="/curriculum/:cid"
                  element={
                    <Curri
                      cont={cont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                    />
                  }
                />
                <Route
                  path="/watch"
                  element={
                    user ? (
                      <Watch
                        cont={cont}
                        games={games}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/sorry" />
                    )
                  }
                />
                <Route
                  path="/curriculum"
                  element={
                    <Curri
                      cont={cont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                    />
                  }
                />
                <Route
                  path="/scourses"
                  element={
                    user && user.isSales ? (
                      <Scourses
                        cont={cont}
                        games={games}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/sorry" />
                    )
                  }
                />
                <Route
                  path="/acourses"
                  element={
                    user && user.admin ? (
                      <Acourses
                        cont={cont}
                        games={games}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/sorry" />
                    )
                  }
                />
                {/* <Route
                path="/allcourses"
                element={
                  user ? (
                    <Allcourses
                      cont={cont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                    />
                  ) : (
                    <Navigate to="/sorry" />
                  )
                }
              /> */}
                <Route
                  path="/admins"
                  element={
                    user && user.admin ? (
                      <Admins
                        cont={cont}
                        games={games}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/sorry" />
                    )
                  }
                />
                <Route
                  path="/teachers"
                  element={
                    user && user.admin ? (
                      <Mentors
                        cont={cont}
                        games={games}
                        user={user}
                        setUser={updateUser}
                      />
                    ) : (
                      <Navigate to="/sorry" />
                    )
                  }
                />
                <Route
                  path="/about"
                  element={
                    <About
                      cont={cont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                    />
                  }
                />
                <Route
                  path="/*"
                  element={
                    <Home
                      cont={cont}
                      games={games}
                      user={user}
                      setUser={updateUser}
                      courses={courses}
                      setCourses={setCourses}
                      error={true}
                      exact
                    />
                  }
                />
              </Routes>
              <Marque />
            </div>
            <div
              className="card flex flex-column align-items-center"
              style={{ height: "90px", background: "transparent" }}
            >
              <ScrollTop icon={<i className="fa fa-arrow-up"></i>} />
            </div>
          </div>
        </Suspense>
      </GlobalContext.Provider>
    </PrimeReactProvider>
  );
}

export default App;

