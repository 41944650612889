import React, { useState, useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Login from './Login'
import Contact from './Contact'
// import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
// import { FaAngleDoubleRight } from 'react-icons/fa';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Dropdown from 'react-bootstrap/Dropdown';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Navy from "./Nav"
import Ctt from "../common/Ctt"
import Form from "react-bootstrap/Form"; 

import Swal from "sweetalert2";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import Button from '@mui/material/Button';
import Bob from './Bubbletest'
import { GlobalContext } from '../../App';  // Import the context from App.js

const Header = ({
  jobs,
  prj,allcc,
  companies,
  gallery,
  careers,
  entry,
  online,
  user,
  yescos,
  cidd,
  db,
  setUser,
  courses,
}) => {
  const [snack, setSnack] = useState('');
  const navigate = useNavigate();
  const cont = useContext(GlobalContext);

  const Focus = async (cid) => {
    const cos = courses.find((el) => el.cid === cid);
    if (cos.deployed) {
      const course = courses.filter((el) => el.cid === cid);
      navigate("/curriculum", { state: course });
    } else {
      Swal.fire({
        title: `Un-available`,
        text: ` Oops ! sorry this course is currently unavailable`,
        icon: "info",
        position: "top-end",

        showConfirmButton: false,
        timer: 2500,
      });
    }
  }

  function setItemWithExpiry(key, value, ttl) {
    const now = new Date();

    // `ttl` is time to live in milliseconds (5 minutes = 5 * 60 * 1000)
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };

    localStorage.setItem(key, JSON.stringify(item));
  }
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ ...newState, open: true });
  };
  const Logout = () => {
    localStorage.removeItem('userid');
    setSnack('Account signed out !');
    setUser(() => null);

    handleClick({ vertical: 'top', horizontal: 'center' });
    // setSnack('');

    navigate('/');
  };
  // const navigate = useNavigate();
  
  // alert(courses.length)
  return (
    <Navbar
      // style={{ maxHeight: "12vh" }}
      expand="md"
      className={`${entry ? `bgtran` : ``} ovf  position-sticky tpp boxsha ugm bll text-light`}
    >
      <Container fluid className="align-items-center ppo">
        <Link to="/" className="navbar-brand  align-items-center  ">
          <Nav style={{ border: "none", outline: "none" }}>
            <img
              style={{ border: "none", outline: "none" }}
              className="logom "
              src="/images/tile.png"
              alt=""
            />
            {/* COdar */}
          </Nav>
        </Link>

        <Navbar.Toggle aria-controls="navbarScroll" className="oci">
 
          <i
            aria-controls="navbarScroll"
            className="fa fa-bars fa-2x text-light"
          ></i>
        </Navbar.Toggle>

        <Navbar.Collapse
          id="navbarScroll"
          className="nnn justify-content-end text-end fw-5 workonn"
        >
          <Nav
            className=" px-4"
            style={{ border: "none", outline: "none" }}
            navbarScroll
          >
            <Link
              to="/login"
              className="d-md-none d-block bsha text-success rounded text-start col-4 fs-4 py-2 px-1"
            >
              Login
            </Link>
            {cont.online ? (
              <Link
                to="/online"
                style={{ border: "none", outline: "none" }}
                className={`${cont.online ? "active text-danger" : ""} nav-item nav-link makewhite `}
              >
                <Ctt
                  className="mt-4"
                  id="tooltip-cart"
                  tooltip="Visit our Online school "
                >
                  <Nav
                    className={`${online ? `text-danger` : `text-light`}  ms3 fw-3`}
                    style={{
                      border: "none",
                      outline: "none",
                      fontWeight: "700",
                      color: cont.online ? "red" : "white",
                    }}
                  >
                    Online
                  </Nav>
                </Ctt>
              </Link>
            ) : (
              ""
            )}
            <NavDropdown
              title={
                <Ctt
                  className="mt-4"
                  id="tooltip-cart"
                  tooltip="Build your workforce "
                >
                  <Link
                    className={`${companies ? "active" : "text-light"}  nnn`}
                    style={{
                      fontWeight: companies ? "900" : "700",
                      color: companies ? "red" : "white",
                    }}
                  >
                    For Companies
                  </Link>
                </Ctt>
              }
              id="navbarScrollingDropdown"
              className="makewhite"
              style={{ fontWeight: "700" }}
            >
              <NavDropdown.Item>
                <Link to="/workforce" className="navbar-brand py-3">
                  Build your workforce
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/learnmore"
                  className="navbar-brand py-3 d-flexh align-items-center  "
                >
                  Learn more
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
            {cont.coses && cont.coses.length > 0 ? (
              <NavDropdown
                className="makewhite"
                title={
                  <Ctt
                    className="mt-4"
                    id="tooltip-cart"
                    tooltip="Browse our Courses"
                  >
                    <Nav
                      className={`${yescos ? `active` : ``} text-start njnn s3`}
                      style={{
                        textAlign: "start",
                        border: "none",
                        outline: "none",
                        fontWeight: yescos ? "900" : "700",
                        color: `${yescos ? `red` : "white"}`,
                      }}
                    >
                      Courses
                    </Nav>
                  </Ctt>
                }
                style={{
                  border: "none",
                  outline: "none",
                  fontWeight: "700",
                  color: "red",
                }}
                id="navbarScrollingDropdown"
              >
                <div className="row col-12 spr">
                  {cont.coses && !cidd && cont.coses.length > 0
                    ? cont.coses.map(({ cid, name, locked }) =>
                        !locked ? (
                          <div className="col-12">
                            <NavDropdown.Item key={cid}>
                              <Link
                                to={`/curriculum/${cid}`}
                                style={{ fontWeight: "700" }}
                                className="ulinkks text-dark text-capitalize"
                              >
                                {name}
                              </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                          </div>
                        ) : (
                          ""
                        )
                      )
                    : ""}
                </div>
                {!allcc ? (
                  <NavDropdown.Item>
                    <Link
                      to="/courses"
                      className="navbar-brand py-3 d-flexh align-items-center  "
                    >
                      View all courses
                    </Link>
                  </NavDropdown.Item>
                ) : (
                  ""
                )}
              </NavDropdown>
            ) : (
              ""
            )}
            {/* <Link
              to="/gallery"
              style={{ border: "none", outline: "none" }}
              className={` nav-item nav-link makewhite `}
            >
              <Ctt className="mt-4" id="tooltip-cart" tooltip="Codar gallery">
                <Nav
                  className={`${gallery ? `text-danger` : `text-light`}  ms3 fw-3`}
                  style={{
                    border: "none",
                    outline: "none",
                    fontWeight: "700",
                    color: cont.online ? "red" : "white",
                  }}
                >
                  Gallery
                </Nav>
              </Ctt>
            </Link> */}
            <Link
              to="/careers"
              style={{ border: "none", outline: "none", fontWeight: "700" }}
              className={`${careers ? "active text-danger" : "text-light"} makewhite nav-item nav-link s3`}
            >
              <Ctt
                className="mt-4"
                id="tooltip-cart"
                tooltip="Get a role with us today"
              >
                <Nav style={{ border: "none", outline: "none" }}>Careers</Nav>
              </Ctt>
            </Link>
            {/* <Ctt className="mt-4" id="tooltip-cart" tooltip="Codar Blog">
              <Nav style={{ border: "none", outline: "none" }}>
                <a
                  href="https://blog.codarhq.com/"
                  style={{ border: "none", outline: "none", fontWeight: "700" }}
                  className={` makewhite nav-item nav-link s3 text-light`}
                >
                  Blog
                </a>
              </Nav>
            </Ctt> */}
          </Nav>
          <div className="d-none d-md-block px-2 ">
            <Link
              to="/login"
              className="bg-danger rounded text-light fs-5 py-2 px-3"
            >
              Login
            </Link>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};


export default Header