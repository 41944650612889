import { useEffect, useState, useRef } from "react";

function useOverflow(ref) {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (ref.current) {
        const hasOverflow = ref.current.scrollWidth > ref.current.clientWidth;
        setIsOverflowing(hasOverflow);
      }
    };
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [ref]);

  return isOverflowing;
}

export default useOverflow