import React from "react";
import styles from "../../styles.module.css";

const Lding = () => {
  const codar = "url(/girls/codarbg.png)";
    
  return (
    <div className="text-center py-2  align-items-center jcent">
      <div
        className={`${styles.container} cbg`}
        style={{
          height: "120vh",
          background: codar,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <img
          className="col-2 mx-auto text-danger mt-5"
          src="/gifs/waitb.gif"
          alt=""
        />
      </div>
    </div>
  );
};

export default Lding;
