import React from "react";
import {Link} from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "font-awesome/css/font-awesome.min.css";
import { ScrollTop } from "primereact/scrolltop";

const Footer = () => {
  return (
    <div
      data-aos="fade-up"
      className="text-white py-4"
      style={{ background: "black" }}
    >
      <Container>
        <Row className="align-items-center">
          <Col md={4} className="text-start mb-3 mb-md-0">
            <img
              data-aos="zoom-in"
              className="logomm "
              src="/girls/codarlogo.png"
              alt=""
            />

            <h5>Mikptech Innovations</h5>
            <p>Stay updated on all our activities</p>
            <h3 className="text-start text-danger">Say hello</h3>
            <a
              className="text-primary text-md-start px-0 text-start"
              href="mailto:contact@mikptech.com.ng"
            >
              contact@mikptech.com.ng
            </a>
          </Col>
          <Col md={4} className="order-md-2 text-start ">
            <h6 className="text-danger">Our locations</h6>
            <h3>Ogun state</h3>
            <p>Promise Estate ,Iyana iyesi  Ota Ogun state</p>
            {/* <p>7, Abeokuta street, Ebute Metta, Yaba, Lagos</p>
            <h3>Island</h3>
            <p>26, Akin Leigh Crescent, Lekki phase1, Lagos</p> */}
          </Col>
          <Col md={4} className="order-md-1 text-start">
            <h6 className="text-danger">Frequently used links</h6>

            <ul className="list-unstyled">
              <li className="mb-3 py-2">
                <Link
                  to="/workforce"
                  className="text-white text-decoration-none "
                >
                  Build your workforce
                </Link>
              </li>
              <li className="mb-3 py-2">
                <Link to="/courses" className="text-white text-decoration-none">
                  Courses
                </Link>
              </li>
              <li className="mb-3 py-2">
                <Link to="/careers" className="text-white text-decoration-none">
                  Careers
                </Link>
              </li>
            </ul>
            <h6 className="text-center">Our Social media links</h6>
            {/* <h6>Follow Us</h6> */}
            <div className="d-flex justify-content-center">
              <Link
                target="__blank"
                to="https://web.facebook.com/mikptech"
                className="mx-2"
              >
                <i className="fa-brands text-light fa-facebook"></i>
              </Link>
              {/* <Link
                target="__blank"
                to="https://x.com/mikptech/status/1830951284721238153?s=46&t=dI8SgeuWpHaMSVb7A8vkwg"
                className="text-white mx-2"
              >
                <i className="fa-brands fa-twitter"></i>
              </Link> */}
              <Link
                target="__blank"
                to="https://www.linkedin.com/in/mikptech/"
                className=" mx-2"
              >
                <i className="fa-brands text-light fa-linkedin"></i>
              </Link>
              <Link
                target="__blank"
                to="https://www.instagram.com/mikptech/"
                className="text-white mx-2"
              >
                <i className="fa-brands fa-instagram"></i>
              </Link>
              <Link
                target="__blank"
                to="https://www.youtube.com/@mikptech"
                className="text-white mx-2"
              >
                <i className="fa-brands fa-youtube"></i>
                {/* <faYoutube className="text-danger" /> */}
              </Link>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="text-center">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} Mikptech Innovations. All Rights
              Reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
