import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
// import Bar from "./batch/Bar"
import { setCookie, getCookie, removeCookie } from "../cookie";
import Offcanvas from "react-bootstrap/Offcanvas";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "react-bootstrap";

import "../common/layout.css";
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
// import { RevealContent, Image, ImageGroup, Reveal } from "semantic-ui-react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { Scheduler } from "@aldabil/react-scheduler";
import Talk from "../common/Talk";
import { ProgressBar } from "primereact/progressbar";
import Prog from "./tools/Progress";
import { motion } from "framer-motion";

// import "../all.css";
// import "animate.css/animate.min.css";
import axios from "axios";
import Swal from "sweetalert2";
// import Spinner from "react-bootstrap/Spinner";
// import "semantic-ui-css/semantic.min.css";
import Na from "../common/Na";
import Lding from "../common/Lding";
import Button from "react-bootstrap/Button";
import { ScheduleMeeting } from "react-schedule-meeting";
import { MeterGroup } from "primereact/metergroup";
import Ctt from "../common/Ctt";

const Db = ({ user, success, cont, setUser, sorry, error }) => {
  // const [contextMenu, setContextMenu] = useState({
  //   xPos: "0px",
  //   yPos: "0px",
  //   show: false,
  // });

  // const handleRightClick = (e) => {
  //   // alert(e.pageX);
  //   e.preventDefault();

  //   setContextMenu({
  //     xPos: `${e.pageX}px`,
  //     yPos: `${e.pageY}px`,
  //     show: true,
  //   });
  // };

  // const handleClick = () => {
  //   setContextMenu({
  //     ...contextMenu,
  //     show: false,
  //   });
  // };
  function cap(text) {
    if (typeof text !== "string" || text.length === 0) {
      return "";
    }
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState("");
  const [alertt, setAlertt] = useState("");
  const [showb, setShowb] = useState(false);
  const [stud, setStud] = useState({});
  const [data, setData] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showp, setShowp] = useState(false);
  const [course, setCourse] = useState({});
  const handleClosep = () => setShowp(false);
  const handleShowp = () => setShowp(true);
  let { userid } = useParams();
  // alert(userid)
  const values = [
    { label: "leads", color: "#35d399", value: data.lp, icon: "pi pi-user" },
    { label: "Students", color: "#45b49e", value: data.sp, icon: "pi pi-book" },
    {
      label: "Teachers",
      color: "#60a5fa",
      value: data.tp,
      icon: "pi pi-pencil",
    },
    { label: "Sales", color: "pink", value: data.ssp, icon: "pi pi-dollar" },
    { label: "Admins", color: "orange", value: data.aps, icon: "pi pi-shield" },
  ];
  const valuesb = [
    {
      label: `${data.mm} Male students`,
      color: "#ca0cd3",
      value: data.males,
      icon: "fa fa-male",
    },
    {
      label: `${data.ff} Female students`,
      color: "blue",
      value: data.females,
      icon: "fa fa-female",
    },
  ];
  const [courses, setCourses] = useState([]);
  const [atimes, setAtimes] = useState([]);
  const [curris, setCurris] = useState([]);
  const [visible, setVisible] = useState(false);
  const [amount, setAmount] = useState(false);
  const [ll, setLl] = useState(true);
  const [llb, setLlb] = useState(true);
  const [studentb, setStudentb] = useState();
  const [pp, setPp] = useState(false);

  let [checkCalled, setCheck] = useState(false);
  const navigate = useNavigate();
  const tuser = { ...user };

  let [nc, setNc] = useState(false);

  useEffect(() => {
    const Check = async () => {
      try {
        
        setNc(true);
        const point = `${cont.api}student/getdata/${userid ? userid : user.userid}`;

        await axios
          .get(`${point}`, {
            headers: {
              userid: userid ? userid : user.userid,
            },
          })
          .then((res) => {
            if (res.data.success) {
              setCookie("userid", res.data.user.userid, 10);

              setCurris((prev) => [...res.data.curris]);
              setData(res.data.data);
              setCourse(res.data.course)

              setUser(() => res.data.user);
              setStud(() => res.data.user);
              const uu = res.data.user;
              if (success) {
                // userid = ""
                Swal.fire({
                  title:uu.transactions > 1
                      ? `Welcome back ${uu.name} !`
                      : `Welcome on board ${uu.name} !`,
                  text: uu.transactions > 1 ? `Your payment was successful `:`Have a blissful ride as you start your tech journey with us today `,
                  icon: "success",
                  position: "center",

                  // showConfirmButton: false,
                  // timer: 5500,
                });
              }
            } else {
              alert("vgbc");
              // navigate("/");
            }
          });
      } catch (error) {
       
        navigate("/");

        console.error("Error:", error);
      }
      setNc(false);
    };

    // Check if jwtt and first are truthy
    if (!checkCalled && user) {
      // Call Check() if conditions are met
      Check();

      // Set the flag to true to indicate that Check() has been called
      setCheck(!checkCalled);
    } else if (!checkCalled) {
      Check();

      // Set the flag to true to indicate that Check() has been called
      setCheck(!checkCalled);
      if (user) {
        Check();

        // Set the flag to true to indicate that Check() has been called
        setCheck(!checkCalled);
      }
    }
  }, [user, checkCalled, userid]);
  const Focus = async (cid) => {
    const cos = courses.find((el) => el.cid === cid);
    if (cos.paid) {
      const course = courses.filter((el) => el.cid === cid);
      navigate("/watch", { state: course });
    } else if (cos.deployed) {
      const course = courses.filter((el) => el.cid === cid);
      navigate("/curriculum", { state: course });
    } else {
      Swal.fire({
        title: `Un-available`,
        text: ` Oops ! sorry this course is currently unavailable`,
        icon: "info",
        position: "top-end",

        showConfirmButton: false,
        timer: 2500,
      });
    }
  };
  const [firstOpen, setFirstOpen] = useState(false);

  const [openn, setOpenn] = React.useState(false);
  const handleOpenn = () => setOpenn(true);
  const handleClosen = () => setOpenn(false);

  function getCurrentMonthYear() {
    const date = new Date();
    const options = { month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  console.log(getCurrentMonthYear());
  const month = getCurrentMonthYear();
  function convertToLocalTime(utcDateString) {
    const utcDate = new Date(utcDateString);

    // Subtract one hour from the date
    utcDate.setHours(utcDate.getHours() - 1);

    return utcDate.toLocaleString(); // Converts to local time string
  }
  function convertToLocalTimeb(utcDateString) {
    const utcDate = new Date(utcDateString);

    // Subtract one hour from the date
    utcDate.setHours(14, 0, 0, 0);
    // utcDate.setHours(utcDate.getHours() + 4);

    return utcDate.toLocaleString(); // Converts to local time string
  }
  function ifex(dateString) {
    // Convert the input date string to a Date object
    const inputDate = new Date(dateString);

    // Get the current date and time
    const currentDate = new Date();

    // Check if the input date is in the past
    return inputDate < currentDate;
  }
  const Pay = async () => {
      setPp(true);

    const Goons = async () => {
      setPp(true);
      const fd = new FormData();
      for (let key in stud) {
        fd.append(key, stud[key]);
      }

      try {
        await axios
          .post(`${cont.api}student/addstudpayment`, fd, {
            headers: { userid: user.userid },
          })
          .then((res) => {
            setVisible(false);
            if (res.data.success) {
              window.location.href = res.data.url
            } else {
              // setPp(true);
              setPp(false);
              setAmount(false);
              Swal.fire({
                title: `error : sorry we couldnt process this transaction at the moment .`,
                text: `error`,
                icon: "error",
              });
            }
          });
      } catch (error) {
        setPp(false);
        setAmount(false);
        Swal.fire({
          title: `Network/server Error:pls check your internet connection.`,
          text: `This is one of the methodologies implored to prevent back engineering`,
          icon: "error",
        });
        //   setLlb(true);
        // Dalert('pls check your network connection');
      }

      setLlb(false);
    };
    try {
      if (stud.amount < user.mininstallment && stud.amount !== stud.owe) {
      setShowb(true)
      setPp(false);
      
      setAlertt(`Oops ! ,sorry you can not pay less than ${user.dmininstallment}`);
      // setPp(false);
      // setVisible(false);

      // Swal.fire({
      //   title: `you can not make payment less than ${user.dmininstallment}`,
      //   icon: "warning",
      // });
      } else if (stud.amount > stud.owe) {
        setShowb(true);
        setAlertt(
          `Oops ! ,sorry you can not pay above ${user.dowe}`
        );

        // Swal.fire({
        //   title: `you can not register payment above what student is in debt of ! (${stud.name}'s debt = ${stud.dowe})`,
        //   icon: "warning",
        // });
      } else {
        // setPp(false);

        Goons();
      }
    }
    catch (err) {
      setPp(false);
    }
  };
  const Run = () => {
    setStud((prev) => ({ ...stud, amount: stud.owe }))
   
    setVisible(true);
  };

  return (
    <div>
      {user ? (
        <Header
          cont={cont}
          run={Run}
          setUser={setUser}
          user={user}
          openn={openn}
          db={true}
        />
      ) : (
        ""
      )}
      <Offcanvas show={visible} onHide={() => setVisible(false)}>
        <Offcanvas.Body>
          <div style={{ marginTop: "" }} className="form-sectionh fera">
            <div className="text-end col-12 px-2">
              <Link onClick={() => setVisible(false)} className="text-end">
                <i className="text-danger fa fa-times fa-2x"></i>
              </Link>
            </div>
            <div
              className="position-absolute bg-danger"
              style={{ zIndex: 55 }}
            >
              <Toast
                show={showb}
                variant="danger"
                onClose={() => setShowb(false)}
                delay={7000}
                autohide
              >
                <Toast.Header>
                  <strong className="me-auto text-danger">
                    Important notice{" "}
                  </strong>
                  <small>Just now</small>
                </Toast.Header>
                <Toast.Body>{alertt}</Toast.Body>
              </Toast>
            </div>
            <div class="login-root ">
              <div class="box-rootd flex-flex flex-direction--column stt ">
                <div class="box-rootd  flex-flex flex-direction--column fg-11 zz-9 ">
                  <div class="box-rootd  flex-flex flex-justifyContent--center  ">
                    <div className="m-0 codar px-1  jbtw">
                      <img className="wel " src="/gifs/pay.gif" alt="" />
                      {/* <h1 className="nname imib"> {cont.name}</h1> */}
                    </div>
                  </div>
                  <div class="formbg-outer ">
                    <div className="row align-items-center">
                      <div className=" col-lg-5 col-md-6 col-sm-12">
                        <img
                          className="wellj  "
                          style={{ height: "40vh" }}
                          src="/girls/courses.png"
                          alt=""
                        />
                      </div>

                      {user ? (
                        <div className="p-fluid row px-2  col-12 mx-auto ">
                          <h2> Course Payment</h2>
                          <h4>
                            Remember, investing in yourself is the best decision
                            you can make. We're excited to have you on this
                            journey!
                          </h4>
                          <h3 className="text-center">amount due : {stud.dowe}</h3>

                          {user.allowinstallments ? (
                            <div className="p-field col-md-9 col-10 mx-auto">
                              <div className="p-inputgroup flex-1 col-12 mx-auto">
                                <span className="p-inputgroup-addon fs-2">
                                  ₦
                                </span>

                                <InputNumber
                                  placeholder="Amount in ₦"
                                  className="text-center"
                                  value={stud.owe}
                                  onChange={(e) =>
                                    setStud((prev) => ({
                                      ...prev,
                                      amount: e.value,
                                    }))
                                  } // Corrected the syntax
                                />
                                <span className="p-inputgroup-addon">.00</span>
                              </div>
                            </div>
                          ) : (
                            <div className="p-field col-md-9 col-10 mx-auto">
                              <div className="p-inputgroup flex-1 col-12 mx-auto text-center">
                                <span className="p-inputgroup-addon fs-2">
                                  ₦
                                </span>
                                <InputNumber
                                  placeholder="Amount in ₦"
                                  className="text-center"
                                  value={stud.owe}
                                  disabled
                                  name="amount"
                                />
                                <span className="p-inputgroup-addon">.00</span>
                              </div>
                            </div>
                          )}

                          {pp ? (
                            <div className="text-center ">
                              
                                <Lding
                                  className="text-center"
                                  animation="border"
                                  variant="light"
                                />
                           
                            </div>
                          ) : (
                            <div className="col-md-9  text-center mx-auto mt-5">
                              <button
                                onClick={Pay}
                                className="button position-relative"
                              >
                                Pay
                              </button>
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {user ? (
        <div class="px-1 row bg-transparent">
          <div
            className="d-none d-lg-block py-1 rounded col-12 col-md-12 col-lg-3 mb-1 bgtran"
            // style={{ height: "fit-content" }}
          >
            <Card>
              <Card.Body className="bg-tran">
                <Card.Img
                  variant="top"
                  src={course.image}
                  className="text-center rounded text-success mb-4"
                />
                <Card.Title className=" text-center text-success">
                  <h3 className="ceo">C.E.O's message</h3>
                </Card.Title>
                <Card.Text>
                  <h3 className="motivate fw-6">{user.motivate}</h3>

                  <h6 className="text-end text-danger  mt-3">
                    {" "}
                    {/* <img className="cim " src="/images/codar.png" alt="" /> */}
                    <span className="d-inline text-dark">codar</span> institute
                    ceo
                  </h6>
                </Card.Text>

                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
              <div className="d-none d-md-block">
                <iframe
                  width="315"
                  height="560"
                  src="https://www.youtube.com/embed/UDwpnrXC5AY"
                  title="Our Course administrators are always here for you."
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
                {/* {user ? (
                  <Card.Img
                    className="px-2 "
                    variant="top"
            
                    height="100vh"
                    src={
                      user.image ? user.image : `${cont.api}admins/noimage.png`
                    }
                  />
                ) : (
                  ""
                )} */}
              </div>
            </Card>
          </div>

          <div class="col-12  col-md-12 col-lg-9 fixkx position-sticky">
            <motion.div
              initial={{ y: -300, opacity: 0 }} // Starts off-screen (above)
              animate={{ y: [0, -50, 0, -25, 0], opacity: 1 }} // Bounces in twice
              transition={{ duration: 2, ease: "easeInOut" }}
            >
              <div class="content-wrapperg py-md-3 px-md-3">
                <div class="row">
                  <div class="col-12  grid-margin stretch-card ">
                    <div class="card  px-2 border border-danger w-100 corona-gradient-card ">
                      <div class="card-body py-2 px-0 px-sm-3">
                        <div class="row  align-items-center">
                          <div class="col-4 d-none d-md-block col-sm-3 col-xl-2">
                            <img
                              src="/images/codar.png"
                              class="rounded gradient-corona-img img-fluid"
                              alt=""
                            />
                          </div>
                          <div class=" col-6 col-md-4 col-lg-3   col-xl-2 ">
                            <h3 class="mb-2 pl-3 mb-sm-0 text-light">
                              {user.name}
                            </h3>
                            {user.indebt ? (
                              <span className="jbtw">
                                <p
                                  onClick={() => {
                                    setStud((prev) => ({ ...stud, amount: stud.owe }))
                                    
                                    setVisible(true)
                                  }}
                                  class="mb-0 font-weight-normal d-none d-sm-block text-light"
                                >
                                  {user.restricted
                                    ? `Grace period expired`
                                    : `Next due date : ${user.due}`}{" "}
                                  &nbsp; &nbsp;
                                  <Ctt
                                    id="tooltip-cart"
                                    tooltip={`Click to complete course payment ${user.dowe}`}
                                  >
                                    <i class="text-success cpp fa-2x fa fa-money-check-alt"></i>
                                  </Ctt>
                                </p>
                              </span>
                            ) : (
                              <h5
                                style={{ cursor: "crosshair" }}
                                class="  mt-1 text-light btn-rounded "
                              >
                                {user.batchname} &nbsp; {user.branch}
                              </h5>
                            )}
                          </div>

                          {user.logintimes < 9 ? (
                            <div class="col-3 mt-5  d-none d-md-block jbtw col-sm-2 col-xl-2 pl-0 text-center">
                              <img
                                src="/gifs/wellb.gif"
                                alt=""
                                className="well"
                              />
                            </div>
                          ) : (
                            ""
                          )}

                          <div class="col-6  col-md-2 col-lg-4 col-xxl-5 col-xl-5 pl-0 text-end">
                            <span
                              style={{ cursor: "crosshair" }}
                              class="btn btn-outline-light btn-rounded get-started-btn"
                            >
                              {user.cname}
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 order-md-2 col-12 mb-5">
                    {data ? (
                      <div>
                        <div class="row">
                          <div class="col-xl-12  col-10 mx-auto py-1 col-md-12 ">
                            <Card bg={"success"} text="light" className="mb-2">
                              <Card.Header>
                                {user.attendance} Attendance
                              </Card.Header>
                              <Card.Body>
                                <Card.Title>{user.matricno}</Card.Title>
                                <Card.Text>Matriculation no </Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                          <div class="col-xl-12  col-10 mx-auto py-1 col-md-12 ">
                            <Card bg={"danger"} text="light" className="mb-2">
                              <Card.Header>Test</Card.Header>
                              <Card.Body>
                                <Card.Title> {user.test}</Card.Title>
                                <Card.Text>Test Score</Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                          <div class="col-xl-12  col-10 mx-auto py-1 col-md-12 ">
                            <Card bg={"primary"} text="light" className="mb-2">
                              <Card.Header>Cgpa</Card.Header>
                              <Card.Body>
                                <Card.Title> {user.cgpa} </Card.Title>
                                <Card.Text>Cgpa</Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                          <div class="col-xl-12  col-10 mx-auto py-1 col-md-12 ">
                            <Card bg={"info"} text="light" className="mb-2">
                              <Card.Header>Course Completion date</Card.Header>
                              <Card.Body>
                                <Card.Title> {user.enddate} </Card.Title>
                                <Card.Text>Expected date</Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </div>
                        {/* {data.length > 0 ? (
                          <div className="d-none d-md-block">
                            
                            <div className="row mb-3">
                              <Prog month={month} dataa={data} />
                            </div>
                          </div>
                        ) : (
                          ""
                        )} */}
                      </div>
                    ) : (
                      <div className="align-items-center">
                        <Lding />
                      </div>
                    )}
                  </div>
                  <div className=" col-md-9 order-md-1 col-12 mb-4">
                    <Card className="cardim">
                      {curris && curris.length > 0 ? (
                        <div className=" custom-scheduler">
                          <Scheduler
                            style={{ backgroundColor: "#f0f0f0" }}
                            view="month"
                            editable={false}
                            deletable={false}
                            disabled={true}
                            events={curris.map((el) => ({
                              event_id: el.curid,
                              title: `${el.course} (${el.batchname})  ${el.topic}`,

                              start: new Date(
                                convertToLocalTime(el.timestring)
                              ),
                              end: new Date(convertToLocalTimeb(el.timestring)),
                              color: ifex(el.hourbstring) ? "red" : "green",

                              bgColor: "red",
                              customInfo: el.topic,
                            }))}
                          />
                        </div>
                      ) : data ? (
                        <div>
                          <div className="col-md-12 col-11 mx-auto">
                            <div className="w-100 align-items-end">
                              {/* <img className="noop" src="/gifs/nt.gif" alt="" /> */}

                              <Talk
                                bg={true}
                                full={true}
                                a={`Hi , ${user.name} `}
                                b={`Welcome to CODARBRAIN`}
                                c={`I am Codarbot ,I will be your guide as you navigate through the system .`}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Card>
                  </div>
                </div>
              </div>

              <footer class="footer  z-3 bg-dark px-3 text-light">
                <div class="d-sm-flex justify-content-center justify-content-sm-between">
                  <span class="text-light d-block text-center text-sm-left d-sm-inline-block">
                    Copyright © codarhq.com {new Date().getFullYear()}
                  </span>
                  <span class="float-none text-light float-sm-right d-block mt-1 mt-sm-0 text-center">
                    innovation never ends
                  </span>
                </div>
              </footer>
            </motion.div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Db;
